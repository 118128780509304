@use "src/styles" as *;

.giftcard-balance-form {

    &__header {
        display: flex;
        justify-content: space-between;
        margin-block-end: var(--spacer-5);
    }

    &__title {
        @extend %heading-6;
    }

    &__card-wrapper {
        display: flex;
        flex-direction: column;
        padding: var(--spacer-4);
        border-radius: 1.5rem;
        background-color: var(--c-background);

        @include for-size(tablet-portrait-up) {
            max-width: 36rem;
            padding: var(--spacer-6);
        }
    }

    &__form-legend {
        margin-right: auto;
        font-weight: var(--fw-regular);
    }

    &__card-number-wrapper {
        display: flex;
        gap: var(--spacer-1);
        row-gap: var(--spacer-3);

        @include for-size(tablet-portrait-up) {
            gap: var(--spacer-4);
        }
    }

    &__card-number-input {
        width: 3rem;
        font-size: .75rem;

        @include for-size(tablet-portrait-up) {
            font-size: 1rem;
            width: 100%;
        }
    }

    &__card-number-field {
        padding-inline: var(--spacer-2);

        @include for-size(tablet-portrait-up) {
            padding-inline: var(--spacer-3);
        }
    }

    &__scratch-code-input {
        width: 100%;
        margin-block-start: var(--spacer-4);
    }

    &__recaptcha-disclaimer {
        margin-block-start: var(--spacer-3);
    }

    &__giftcard-balance {
        font-size: 1.25rem;
        font-weight: var(--fw-medium);
        margin-block-start: var(--spacer-4);
    }

    &__error-label,
    &__submit-button {
        margin-block-start: var(--spacer-4);
    }
}
