@use "src/styles" as *;

.product-media-slider {

    &__active-item-wrapper {
        width: 100%;
        aspect-ratio: 1;
    }

    &__active-item {
        width: 100%;
        height: 100%;
    }

    &__active-item-image {
        width: 100%;
        height: 100%;
        background-color: var(--c-white);
    }

    &__selector {
        display: flex;
        gap: var(--spacer-2);
        width: 100%;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        margin-block-start: var(--spacer-3);
        padding-inline: var(--wrapper-padding);
    }

    &__selector-item {
        position: relative;
        scroll-snap-align: center;
        background-color: var(--c-white);

        &--is-video {
            background-color: var(--c-green-25);
        }
    }

    &__selector-button {
        @extend %button--no-background;

        padding: 0;

        &::after {
            content: unset;
        }
    }

    &__selector-video-icon-wrapper {
        @extend %flex-align-center;
        @include circle(2.5rem);

        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        background-color: var(--c-white);
    }

    &__selector-video-icon {
        color: var(--c-black);
    }

    &__selector-image {
        @include square(5rem);

        position: relative;
        z-index: 0;
        max-height: 5rem;
    }
}
