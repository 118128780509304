@use "src/styles" as *;

.brand-navigation {

    &__list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-block: var(--spacer-6) 0;

        @include for-size(tablet-portrait-up) {
            margin-block: var(--spacer-6);
        }
    }

    &__navigation-text,
    &__navigation-anchor-link,
    &__section-heading {
        padding-block: 0;
        padding-inline: var(--spacer-2);
        font-size: 1.75rem;
        font-weight: var(--fw-bold);
    }

    &__navigation-anchor-link {
        color: var(--c-green-dark);
        transition: color .1s ease-in-out;

        &:hover,
        &:focus {
            color: var(--c-green)
        }
    }

    &__navigation-text {
        color: var(--c-cement);
    }

    &__section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &__section-wrapper {
        border-block-end: 1px solid var(--c-green-dark);
    }

    &__section-anchor-spacing {
        margin-bottom: 96px;

        @include for-size(tablet-portrait-up) {
            margin-bottom: 164px;
        }
    }

    &__section-heading {
        color: var(--c-green-dark);
    }

    &__section-list {
        margin: 0;
        padding: 0;
        padding-block-end: var(--spacer-2);
        list-style: none;
    }

    &__section-list-item {
        padding: var(--spacer-2);
        transition: background-color .1s ease-in-out;

        &:hover,
        &:focus {
            background-color: var(--c-white);
            cursor: pointer;
            box-shadow: var(--box-shadow);
        }
    }

    &__brand-link {
        @include increase-clickable-area;

        display: block;
        width: 100%;
        font-size: .875rem;
    }
}
