@use "src/styles" as *;

.button-examples {

    &__grid {
        display: flex;
        flex-wrap: wrap;
        gap: var(--spacer-5);
        width: 100%;
        max-width: 50rem;
    }

    &__button {

        & + & {
            margin-block-start: var(--spacer-3);
        }

        &--is-outline {
            @extend %button--is-outline;

            color: var(--c-black);
        }

        &--no-background {
            @extend %button--no-background;

            color: var(--c-black);
        }
    }
}
