@use "src/styles" as *;

.login-page {

    &__wrapper {
        --wrapper-padding: 0;

        display: flex;
        flex-direction: column;
        gap: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            --wrapper-padding: var(--spacer-5);

            flex-direction: row;
        }
    }

    &__register-call-to-action {
        flex: 1;

        @include for-size(laptop-up) {
            flex: 2;
        }
    }

    &__login-form {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: var(--spacer-5);
        padding: var(--spacer-5);
        background-color: var(--c-white);
    }

    &__section {
        margin-block: var(--spacer-5);
    }
}
