.input-examples {

    &__input {
        max-width: 40rem;

        & + & {
            margin-block-start: var(--spacer-4);
        }
    }

    &__select-input {
        position: relative;
        z-index: 10;
    }
}
