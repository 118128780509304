@use "src/styles" as *;

.related-blog-posts {

    &__blog-posts-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__blog-wrapper {
        display: flex;
    }

    &__title {
        @extend %heading-4;

        margin-block-end: var(--spacer-6);
        color: var(--c-green-dark);
    }
}
