.product-call-to-action {
    display: flex;
    gap: var(--spacer-3);

    &__cart-button {
        flex: 1 1 auto;
    }

    &__wishlist-button {
        flex: 0 0 auto;
        color: var(--c-green-dark);
        border-color: var(--c-green-dark);
        background-color: var(--c-white);
    }
}
