.icon-examples {

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(4.75rem, 1fr));
        gap: var(--spacer-4);
        width: 100%;
        max-width: 44rem;
        margin-block-start: var(--spacer-5);
    }

    &__icon {
        height: 2rem;
        stroke-width: 6px;
    }

    &__icon-accent {
        fill: var(--c-green-dark);
    }
}
