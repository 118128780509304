@use "src/styles" as *;

.typography-examples {

    &__item {
        display: block;

        & + & {
            margin-block-start: var(--spacer-4);
        }
    }

    &__heading {
        line-height: 1;

        &--1 {
            @extend %heading-1;
        }

        &--2 {
            @extend %heading-2;
        }

        &--3 {
            @extend %heading-3;
        }

        &--4 {
            @extend %heading-4;
        }

        &--5 {
            @extend %heading-5;
        }

        &--6 {
            @extend %heading-6;
        }

        &--7 {
            @extend %heading-7;
        }
    }

    &__link {
        display: block;
        width: fit-content;
        font-family: var(--f-primary);
        font-weight: var(--fw-medium);
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: .32px;

        &--is-small {
            font-size: .875rem;
            line-height: 1.75;
        }

        & + & {
            margin-block-start: var(--spacer-4);
        }
    }
}
