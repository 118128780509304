@use "src/styles" as *;

.product-detail-renderer {

    &__detail-wrapper {
        max-width: unset;
        margin-block-start: var(--spacer-5);
        padding-inline: unset;

        @include for-size(tablet-portrait-up) {
            max-width: var(--wrapper-width);
            padding-inline: var(--wrapper-padding);
        }
    }

    &__information-wrapper {
        margin-block-start: var(--spacer-5);
    }
}
