@use "src/styles" as *;

.service-section {
    padding-block: var(--spacer-6);
    background-color: var(--c-white);

    @include for-size(tablet-portrait-up) {
        padding-block: var(--spacer-7);
    }

    &__wrapper {
        display: flex;
        gap: var(--spacer-5);
        align-items: center;
        flex-wrap: wrap;
    }

    &__title {
        font-family: var(--f-primary);
        font-weight: var(--fw-medium);
        margin-block-end: var(--spacer-5);
    }

    &__column {
        flex: 50%;

        &:first-child {
            flex: 100%;
        }

        @include for-size(tablet-landscape-up) {
            flex: 1;
            padding-inline: var(--spacer-3);
            border-inline-start: 1px solid var(--c-black);

            &:first-child {
                flex: 2;
                padding-inline-start: unset;
                border-inline-start: none;
            }
        }
    }

    &__form {

        @include for-size(tablet-landscape-up) {
            margin-inline-end: var(--spacer-6);
        }
    }
}
