@use "src/styles" as *;

.range-input-handle {
    @extend %draggable;
    @include circle(var(--range-input-handle-size));

    position: absolute;
    left: negative(calc(var(--range-input-handle-size) / 2));
    z-index: 20;
    cursor: grab;
    touch-action: none;
    will-change: transform;

    &::before {
        @extend %input-label;

        content: attr(data-value);
        display: block;
        position: relative;
        inset-inline-start: 50%;
        width: fit-content;
        text-align: center;
        translate: -50% -1.5rem;
        scale: 1;
        transition: scale .1s ease, opacity .1s ease;

        @include rtl {
            translate: 50% -1.5rem;
        }
    }

    &:focus,
    &--is-active {
        z-index: 40;
        cursor: grabbing;

        &::before {
            scale: 1.125;
            opacity: 1;
        }
    }

    &--is-disabled {
        pointer-events: none;
        background-color: var(--c-black);

        &::before {
            opacity: .5;
        }
    }

    &--crossed-mid-point {
        z-index: 30;
    }

    &--is-hidden {

        &::before {
            opacity: 0;
        }

        & + &::before {
            opacity: 1;
        }
    }
}
