.faq-skeletons {
    max-width: var(--wrapper-width);

    &__item {
        border-bottom: 1px solid var(--c-green);
    }

    &__question-wrapper {
        max-width: 48rem;
    }

    &__question {
        margin-block: var(--spacer-4);
        color: var(--c-green-75);
    }
}
