@use "src/styles" as *;

.color-swatches {

    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--spacer-1);
    }

    &__item {
        @include circle(1rem);

        height: 1rem;
        border: 1px solid var(--c-background);
        background: var(--c-swatch);
    }

    &__excess-label {
        font-size: .75rem;
        font-weight: var(--fw-medium);
    }
}
