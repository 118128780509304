@use "src/styles" as *;

.interactive-product-header {
    position: relative;
    z-index: 200;

    &__product-items {
        @extend %area-extend;
    }

    &__picture {
        width: 100%;
    }
}
