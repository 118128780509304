@use "src/styles" as *;

.range-input-marks {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 1px);
    margin: 0;
    padding: 0;

    &__mark {
        @extend %flex-align-center;

        width: 1px;
        flex-direction: column;
        font-size: 1em;
        list-style-type: none;

        &:last-child {
            translate: 100% 0;
        }

        &::before {
            content: "";
            display: block;
            width: 1px;
            height: .75em;
            margin-block-end: .25rem;
            background-color: currentColor;
        }
    }
}
