@keyframes navigation-fade {
    0%,
    100% {
        translate: 0 0;
        opacity: 1;
    }
    49% {
        translate: var(--fade-offset-start) 0;
        opacity: 0;
    }
    51% {
        translate: var(--fade-offset-end) 0;
        opacity: 0;
    }
}

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes horizontal-scroll {
    from { transform: translateX(0); }
    to { transform: translateX(-100%); }
}

@keyframes marquee {
    from { translate: 0 0; }
    to { translate: -50% 0; }
}

@keyframes skeleton-load {
    from { translate: -100% 0; }
    to { translate: 100% 0; }
}

@keyframes spin {
    to {
        rotate: 1turn;
    }
}
