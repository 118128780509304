@use "src/styles" as *;

.instant-search-product-filter-bar {
    position: relative;

    &__wrapper  {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3.5rem;

        @include for-size(laptop-up) {
            height: 4.25rem;
        }
    }

    &__filter-button-wrapper {
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: var(--spacer-3);
        left: 0;
        right: 0;
        z-index: 10;

        @include for-size(laptop-up) {
            position: relative;
            inset: unset;
        }
    }

    &__filter-button {
        box-shadow: var(--box-shadow);

        @include for-size(laptop-up) {
            font-weight: var(--fw-regular);
            color: var(--c-black);
            background-color: transparent;
            box-shadow: unset;
            transition: .1s ease-in-out background-color,
                .1s ease-in-out color;

            &--is-active {
                color: var(--c-white);
                background-color: var(--c-primary);
            }
        }
    }

    &__highlighted-filters-wrapper {
        display: none;

        @include for-size(laptop-up) {
            display: flex;
            gap: var(--spacer-2);
            margin-inline-start: var(--spacer-2);
        }
    }

    &__products-count {
        margin-inline-end: var(--spacer-4);
        font-weight: var(--fw-regular);
        text-transform: lowercase;
        color: var(--c-green-dark);

        @include for-size(laptop-up) {
            margin-inline-start: auto;
        }
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-4);
        position: fixed;
        inset: 0;
        z-index: 100;
        width: 100%;
        padding-block: var(--spacer-5);
        padding-inline: var(--spacer-4);
        overflow-y: auto;
        background-color: var(--c-background);
        box-shadow: var(--box-shadow);
        translate: 0 200%;
        transition: translate .25s var(--ease-out-strong), opacity .25s ease;

        @include for-size(laptop-up) {
            align-items: flex-start;
            position: absolute;
            z-index: 30;
            inset: unset;
            right: 0;
            max-width: 24rem;
            pointer-events: none;
            translate: 0 0;
            opacity: 0;
        }

        &--is-active {
            translate: 0 0;

            @include for-size(laptop-up) {
                pointer-events: unset;
                opacity: 1;
            }
        }
    }

    &__heading {
        @extend %heading-7;
    }

    &__accordion {
        width: 100%;
        padding-inline-start: 0;
    }

    &__accordion-heading {
        font-family: var(--f-primary);
        font-weight: var(--fw-regular);
        line-height: 1.125;
        text-transform: capitalize;
        color: var(--c-black);
    }

    &__results-button {
        width: 100%;
        margin-block-start: auto;
    }
}
