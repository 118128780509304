@use "src/styles" as *;

.section-footer {
    display: flex;
    align-items: flex-end;
    gap: var(--spacer-3);

    @include for-size(tablet-portrait-up) {
        gap: var(--spacer-4);
    }

    &__divider {
        @extend %divider-end-start;

        flex: 1;
    }

    &__link {
        @extend %button--is-outline;

        color: currentColor;
    }
}
