@use "src/styles" as *;

.brand-overview-page {

    &__brand-overview {
        margin-block-start: var(--spacer-6);
    }

    &__bottom-block-renderer {
        margin-block-start: var(--spacer-7);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-8);
        }
    }
}
