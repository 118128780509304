@use "src/styles" as *;

.spinner {
    @include circle(3rem);

    display: block;
    border-radius: 50%;
    border: 5px solid var(--c-green-dark);
    border-block-end: 5px solid transparent;
    animation: spin 1s linear infinite;
}
