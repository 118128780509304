@use "src/styles" as *;

.link-suggestions {
    min-width: fit-content;

    @include for-size(laptop-up) {
        min-width: 15rem;
    }

    &__label {
        font-size: .875rem;
        font-weight: var(--fw-bold);
        padding-block: var(--spacer-4);
    }

    &__list-item {
        @extend %button--no-background;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: var(--spacer-2);
        min-height: 3rem;
        width: 100%;
        padding-block: var(--spacer-2);
        padding-inline: var(--spacer-3);
        border-block-end: 1px solid var(--c-green-75);
        color: var(--c-green-dark);
        font-weight: var(--fw-regular);
        text-align: start;
    }
}
