@import "src/styles";

.range-input {
    --range-input-width: 0px;
    --range-input-min: 0;
    --range-input-max: 100;
    --range-input-range-size: 0;
    --range-input-handle-size: 2rem;

    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    font-weight: var(--fw-bold);

    @include for-size(tablet-portrait-up) {
        --range-input-handle-size: 1.5rem;
    }

    @include touch-screen {
        --range-input-handle-size: 2.5rem;
    }

    &:focus-within &__bar {
        border-color: var(--c-accent);
    }

    &__label {
        justify-content: flex-start;
        margin-block-end: 2.5rem;
    }

    &__input-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: .75rem;

        &--is-disabled {
            cursor: not-allowed;
            filter: grayscale(1);
            opacity: .5;
        }
    }

    &__handle {

        &--min {
            transform: translateX(calc(#{var(--range-input-width)} * var(--range-input-min)));

            @include rtl {
                transform: translateX(calc(#{var(--range-input-width)} * (1 - var(--range-input-min))));
            }
        }

        &--max {
            transform: translateX(calc(#{var(--range-input-width)} * var(--range-input-max)));

            @include rtl {
                transform: translateX(calc(#{var(--range-input-width)} * (1 - var(--range-input-max))));
            }
        }
    }

    &__marks {
        @extend %input-label;

        color: var(--c-grey);
    }
}
