@use "src/styles" as *;

.tab-item {
    position: relative;
    padding-block-end: var(--spacer-3);
    overflow: clip;
    flex-shrink: 0;
    color: var(--c-accent);

    &::after {
        content: "";
        position: absolute;
        z-index: 20;
        inset-block-end: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 2px;
        background-color: var(--c-black);
        transition: translate calc(.3s * var(--transition-multiplier)) ease-in-out;
        translate: negative(calc(100% + 1px)) 0;
    }

    &--is-active {
        color: var(--c-black);

        &::after {
            translate: 0 0;
        }
    }

    &__button {
        @extend %button--no-background;

        padding: 0;
        overflow: unset;
        color: currentColor;
        font-size: inherit;
        cursor: pointer;

        &:enabled:hover,
        &:enabled:focus {
            color: var(--c-black);
        }

        &::after {
            content: unset;
        }
    }

    &__button-label {
        font-family: var(--f-display);
        font-weight: var(--fw-bold);
        line-height: 1.25;
        text-transform: uppercase;
    }
}
