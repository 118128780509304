@use "src/styles" as *;

.store-suggestions {

    @include for-size(laptop-up) {
        min-width: 70%;
    }

    &__label {
        font-size: .875rem;
        font-weight: var(--fw-bold);
        padding-block: var(--spacer-4);
    }

    &__item {
        @extend %button--no-background;

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: var(--spacer-3);
        width: 100%;
        min-height: 3.25rem;
        padding-inline-start: var(--spacer-3);
        font-weight: var(--fw-regular);
        text-align: start;
        color: var(--c-green-dark);
        border-block-end: 1px solid var(--c-green-75);
    }

    &__info-wrapper {
        display: flex;
        gap: var(--spacer-2);
        position: relative;
        width: 100%;
    }

    &__store-link {
        min-width: 8rem;
        font-weight: var(--fw-bold);

        &::after {
            @extend %area-extend;

            content: "";
        }
    }

    &__location-open-status {
        margin-inline-start: auto;

        @include for-size(laptop-up) {
            margin-inline-start: unset;
            margin-inline-end: auto;
        }
    }

    &__route-link {
        @extend %button--no-background;

        color: var(--c-green-dark);
    }

    &__link-icon {
        color: var(--c-black);
    }

    &__show-all-link {
        @extend %button--no-background;

        width: 100%;
        margin-block-start: var(--spacer-5);
        font-weight: var(--fw-regular);
        color: var(--c-green-dark);
        border-block-end: 1px solid var(--c-green-75);
    }

    &__show-all-link-label {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}
