@use "src/styles" as *;

$component: "product-popup-item";

.#{$component} {
    --product-popup-button-size: 2.5rem;
    --product-popup-card-offset: var(--spacer-2);

    position: absolute;
    top: var(--product-mobile-position-y);
    left: var(--product-mobile-position-x);
    z-index: 10;
    translate: -50% -50%;

    @include for-size(tablet-landscape-up) {
        top: var(--product-position-y);
        left: var(--product-position-x);
    }

    &--is-open {
        z-index: 20;

        .#{$component}__button {
            color: var(--c-white);
            background-color: var(--c-green-dark);
            rotate: 0turn;
        }
    }

    &__button {
        @include circle(var(--product-popup-button-size));

        position: relative;
        z-index: 10;
        padding: 0;
        color: var(--c-green-dark);
        background-color: var(--c-white);
        transition: color, background-color, rotate;
        transition-duration: .3s;
        transition-timing-function: var(--ease-out-strong);
        rotate: -.25turn;
    }

    &__dialog {
        position: static;
        min-height: max-content;
        padding: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            position: fixed;
            width: 16.25rem;
            height: 24rem;
            padding: 0;
            margin: 0;
            background-color: transparent;

            &--inset-block-start {
                top: 0;

                .#{$component}__product-card {

                    @starting-style {
                        clip-path: inset(0 0 100% 0);
                    }
                }
            }

            &--inset-block-end {
                bottom: 0;

                .#{$component}__product-card {
                    align-self: flex-end;

                    @starting-style {
                        clip-path: inset(100% 0 0 0);
                    }
                }
            }

            &--inset-inline-start {
                left: unset;
                right: calc(100% - var(--product-popup-card-offset));
            }

            &--inset-inline-end {
                left: calc(100% - var(--product-popup-card-offset));
            }
        }
    }

    &__dialog-close-button {

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &__product-card-wrapper {
        display: flex;
        height: 100%;
    }

    &__product-card {
        width: 100%;
        background-color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            position: relative;
            padding: var(--spacer-3);
            transition: clip-path .5s var(--ease-out-strong);
            clip-path: inset(0 0 0 0);
        }
    }
}
