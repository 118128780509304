@use "src/styles" as *;

.account-tab-header {
    padding: var(--spacer-5);
    border-block-end: 1px solid var(--c-green-50);

    &__title {
        @extend %heading-5;
    }

    &__description {
        margin-block-start: var(--spacer-5);
    }
}
