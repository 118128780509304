.error-label {
    display: block;
    font-size: .875rem;
    line-height: 1.5;
    text-align: start;
    color: var(--c-error);

    &:first-letter {
        text-transform: uppercase;
    }
}
