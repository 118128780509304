@use "src/styles" as *;

.icon-columns {

    &__title {
        @extend %heading-5;

        font-family: var(--f-display);
        font-weight: var(--fw-regular);
        color: var(--c-green-dark);
    }

    &__columns {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: var(--spacer-3);
        margin-block-start: var(--spacer-5);

        @include for-size(laptop-up) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
