.index-page {

    &__section {
        padding-block-start: var(--spacer-5);

        & + & {
            margin-block-start: var(--spacer-5);
            border-block-start: 1px solid var(--c-black);
        }
    }

    &__section-examples {
        margin-block-start: var(--spacer-5);
    }
}
