.contact-option {
    --c-contact-status-indicator: var(--c-warning);

    display: flex;
    flex-direction: column;
    gap: var(--spacer-2);

    &__link {
        display: inline-block;
        width: fit-content;

        &:hover,
        &:focus {
            color: var(--c-green-dark);
        }
    }

    &__status {
        display: flex;
        gap: var(--spacer-4);
        align-items: center;

        &--is-open {
            --c-contact-status-indicator: var(--c-success);
        }

        &--is-closed {
            --c-contact-status-indicator: var(--c-error);
        }
    }

    &__status-indicator {
        flex: 0 0 auto;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: var(--c-contact-status-indicator);
    }
}
