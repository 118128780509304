@use "src/styles" as *;

.play-button {
    @include circle(4rem);

    padding: 0;
    color: var(--c-accent);
    background-color: var(--c-white);

    &:enabled:hover &__icon,
    &:enabled:focus &__icon {
        scale: 1.25;

        @include prefers-reduced-motion {
            scale: 1;
        }
    }

    &__icon {
        height: 1.5rem;
        transition: scale .3s ease;
    }
}
