@forward "buttons";
@forward "divider";
@forward "html";
@forward "inputs";
@forward "layout";
@forward "lists";
@forward "typography";

%flex-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

%area-extend {
    position: absolute;
    inset: 0;
}

%skeleton-fade {
    position: relative;
    overflow: clip;

    &::after {
        @extend %area-extend;

        content: "";
        background: linear-gradient(
            90deg,
            transparent 0%,
            rgba(var(--c-white-rgb), .25) 50%,
            transparent 100%
        );
        animation: skeleton-load 2s ease infinite;
    }
}

%draggable {
    background-color: var(--c-white);
    border: 1px solid currentColor;
}

