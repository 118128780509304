@use "src/styles" as *;

.product-sidebar {
    @extend %remove-excess-margin-block;

    padding: var(--spacer-5);
    background-color: var(--c-white);

    &__brand-wrapper {
        margin-block: var(--spacer-3) var(--spacer-2);
    }

    &__brand {
        font-family: var(--f-primary);
        font-weight: var(--fw-medium);
        font-size: .75rem;
        letter-spacing: normal;
        text-transform: uppercase;
    }

    &__name {
        margin-block: var(--spacer-3);
    }

    &__price {
        margin-block: var(--spacer-3);
        font-size: 1.25rem;
        font-weight: var(--fw-bold);
        text-align: end;
        color: var(--c-black);
    }

    &__description {
        @extend %html-format;
    }

    &__notices {
        margin-block-start: var(--spacer-3);
        font-weight: var(--fw-bold);
    }

    &__color-wrapper {
        margin-block-start: var(--spacer-3);
    }

    &__color-swatches {
        margin-block-start: var(--spacer-3);
    }

    &__call-to-action {
        margin-block: var(--spacer-4);
    }

    &__attributes {
        margin-block: var(--spacer-3);
    }

    &__attributes-title {
        @extend %heading-7;

        margin-block-end: var(--spacer-3);
        font-weight: var(--fw-regular);
    }

    &__list {
        @extend %checked-list;
    }
}
