@use "src/styles" as *;

$component: "highlighted-blog-post";

.#{$component} {
    position: relative;
    overflow: clip;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: var(--spacer-5);
        position: relative;
        width: 100%;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }

        &--image-left {

            @include for-size(tablet-portrait-up) {
                flex-direction: row-reverse;
            }

            .#{$component}__title-wrapper {

                @include for-size(tablet-landscape-up) {
                    align-self: flex-end;
                }
            }

            .#{$component}__title {
                left: unset;
                right: 0;
            }
        }
    }

    &__image-wrapper {
        display: none;
        width: 100%;
        height: 100%;

        @include for-size(tablet-landscape-up) {
            display: flex;
            min-height: 36rem;
            max-height: 42rem;
        }

        @include for-size(desktop-up) {
            max-height: 36rem;
        }
    }

    &__picture {
        width: 100%;
    }

    &__image {
        object-fit: cover;
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: flex-start;
        align-self: flex-start;

        @include for-size(tablet-landscape-up) {
            max-width: 28rem;
        }
    }

    &__meta-wrapper {
        font-family: var(--f-primary);
        font-weight: var(--fw-medium);
        font-size: .75rem;
        text-transform: uppercase;
        line-height: 2;
        letter-spacing: .72px;
        color: var(--c-charcoal);
    }

    &__publish-date {
        margin-inline-start: var(--spacer-3);
        padding-inline-start: var(--spacer-3);
        border-inline-start: 1px solid var(--c-green-75);
    }

    &__title-wrapper {
        flex-basis: 100%;
        position: relative;
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-landscape-up) {
            margin-block-start: var(--spacer-6);
        }
    }

    &__title {
        @extend %heading-1;

        @include for-size(tablet-landscape-up) {
            position: absolute;
            left: 0;
            width: 49.5rem;
        }
    }

    &__mobile-picture {
        width: 100%;
        max-height: 16.25rem;
        margin-block-start: negative(var(--spacer-3));

        @include for-size(tablet-portrait-up) {
            max-height: 24rem;
            margin-block-start: negative(var(--spacer-5));
        }

        @include for-size(tablet-landscape-up) {
            display: none;
            visibility: hidden;
        }
    }

    &__mobile-image {
        width: 100%;
        height: unset;
        object-fit: cover;
    }

    &__description {
        margin-block-start: var(--spacer-5);
        font-family: var(--f-elegant);
        font-size: 1.5rem;
        font-style: italic;
        font-weight: var(--fw-regular);
        line-height: 1.25;

        @include for-size(tablet-landscape-up) {
            margin-block-start: var(--spacer-7);
        }
    }

    &__button {
        margin-block-start: var(--spacer-5);
    }
}
