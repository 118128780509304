@use "src/styles" as *;

.search-drawer {

    &__link {
        @extend %button--no-background;

        padding: var(--spacer-2);
    }

    &__link-icon {
        width: 1.5rem;
        height: 1.5rem;
        color: var(--c-black);
        background: none;
    }

    &__wrapper {
        --wrapper-width: 90rem;

        position: absolute;
        inset: 0;
        top: var(--menu-height);
        z-index: 100;
        width: 100%;
        height: calc(100dvh - var(--menu-height));
        padding-block-end: var(--spacer-5);
        overflow-y: auto;
        pointer-events: none;
        color: var(--c-green-dark);
        background-color: var(--c-background);
        transition: opacity .3s ease, translate .3s var(--ease-out-strong);
        opacity: 0;
        translate: 0 negative(var(--spacer-2));

        @include for-size(laptop-up) {
            --wrapper-width: 70rem;

            position: absolute;
            height: fit-content;
            padding-block: var(--spacer-5) var(--spacer-8);
            padding-inline: var(--spacer-8);
            border-end-start-radius: .25rem;
            border-end-end-radius: .25rem;
            box-shadow: var(--box-shadow);
        }

        &--is-open {
            pointer-events: all;
            opacity: 1;
            translate: 0 0;

            @include for-size(phone-only) {
                @include prevent-body-scroll;
            }
        }
    }

    &__results-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: var(--spacer-5);

        @include for-size(laptop-up) {
            flex-direction: row;
            gap: var(--spacer-6);
        }
    }

    &__show-all-results-button {
        @extend %button--is-outline;

        margin-block-start: var(--spacer-5);
        color: var(--c-black);
    }
}
