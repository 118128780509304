@use "src/styles" as *;

$component: "checkout-thank-you-page";

.#{$component} {

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-5);
        position: relative;
        padding-block: var(--spacer-6);
        border-block: 2px solid var(--c-green-dark);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            justify-content: flex-end;
        }

        &--image-left {

            @include for-size(tablet-portrait-up) {
                flex-direction: row-reverse;
                justify-content: flex-start;

                .#{$component}__title {
                    width: 35rem;
                    margin-inline-start: negative(var(--spacer-8));
                }
            }
        }
    }

    &__title-wrapper {

        @include for-size(tablet-portrait-up) {
            display: flex;
            flex-direction: column;
            width: 23rem;
        }

        @include for-size(tablet-landscape-up) {
            width: 27.625rem;
        }
    }

    &__heading {
        @extend %heading-1;

        margin-block-end: var(--spacer-5);
    }

    &__text {
        margin-block-end: var(--spacer-4);
    }

    &__intro {
        font-family: var(--f-elegant);
        font-size: 1.5rem;
        font-weight: var(--fw-regular);
    }

    &__postscript {
        font-weight: var(--fw-medium);
        color: var(--c-green-dark);
    }

    &__social-wrapper {
        display: flex;
        gap: var(--spacer-3);
        color: var(--c-green-dark);
    }

    &__social-media-group {
        display: flex;
        gap: var(--spacer-3);
    }

    &__social-media-link {
        display: block;
        width: 100%;
        height: 100%;
        color: currentColor;
        text-decoration: none;
    }

    &__social-media-link-icon {
        width: 1rem;
        height: 1.5rem;
    }

    &__image-wrapper {
        max-height: 16.25rem;
        overflow: clip;

        @include for-size(tablet-portrait-up) {
            width: 56.75rem;
            min-width: 22rem;
            min-height: 35.75rem;
        }
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__image {
        object-fit: cover;
    }

    &__block-renderer {
        margin-block-start: var(--spacer-8);
    }
}
