@use "src/styles" as *;

.account-page {

    &__wrapper {
        position: relative;
        margin-block-end: var(--spacer-8);
        padding-inline: 0;

        @include for-size(tablet-landscape-up) {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-column-gap: 1rem;
            padding-inline: var(--wrapper-padding);
        }
    }

    &__sidebar {
        display: none;
        position: sticky;
        top: calc(var(--menu-height) + var(--submenu-height));
        height: fit-content;

        @include for-size(tablet-landscape-up) {
            display: inline-grid;
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    &__contents {
        padding-block-end: var(--spacer-5);
        border-radius: .125rem;
        background-color: var(--c-white);

        @include for-size(tablet-landscape-up) {
            display: inline-grid;
            grid-column-start: 3;
            grid-column-end: 13;
        }
    }

    &__block {
        margin-block-end: var(--spacer-5);
    }
}
