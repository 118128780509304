.newsletter-form {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-3);

    &__input {
        width: 100%;
    }

    &__submit-button {
        background-color: var(--c-black);
    }
}
