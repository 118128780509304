@use "src/styles" as *;

$component: "category-header";

.#{$component} {
    position: relative;
    overflow: hidden;

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-5);
        position: relative;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            justify-content: flex-end;
        }

        &--image-left {

            @include for-size(tablet-portrait-up) {
                flex-direction: row-reverse;
                justify-content: flex-start;

                .#{$component}__title {
                    width: 35rem;
                    margin-inline-start: negative(var(--spacer-8));
                }
            }
        }
    }

    &__image-wrapper {
        max-height: 16.25rem;
        overflow: clip;

        @include for-size(tablet-portrait-up) {
            width: 56.75rem;
            min-width: 22rem;
            min-height: 35.75rem;
        }
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__image {
        object-fit: cover;
    }

    &__title-wrapper {

        @include for-size(tablet-portrait-up) {
            display: flex;
            flex-direction: column;
            width: 23rem;
        }

        @include for-size(tablet-landscape-up) {
            width: 27.625rem;
        }
    }

    &__title {
        @extend %heading-1;

        @include for-size(tablet-portrait-up) {
            position: absolute;
            inset-block-start: var(--spacer-6);
            width: 49.5rem;
            margin-block-start: var(--spacer-6);
        }
    }
}
