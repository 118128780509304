@use "src/styles" as *;

.not-found {

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-block-start: var(--spacer-8);
    }

    &__logo {
        width: 8.5rem;
        height: 5rem;
    }

    &__heading {
        @extend %heading-5;

        margin-block-start: var(--spacer-5);
        color: var(--c-green-dark);
    }

    &__description {
        max-width: 28rem;
        margin-block-start: var(--spacer-3);
        text-align: center;
    }

    &__button-wrapper {
        display: flex;
        gap: var(--spacer-5);
        margin-block-start: var(--spacer-5);
    }

    &__question-button {
        @extend %button--is-outline;

        color: var(--c-black);
    }
}
