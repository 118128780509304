@use "src/styles" as *;

.icon-column {
    display: flex;
    padding: var(--spacer-5);
    background-color: var(--c-green-25);

    &__icon {
        flex: 0 0 auto;
        width: 2rem;
        height: 2rem;
        margin-inline-end: var(--spacer-3);
        color: var(--c-green-dark);
    }

    &__content {
        flex: 1 1 auto;
    }

    &__title {
        @extend %heading-6;

        font-size: 1.125rem;
        font-weight: var(--fw-medium);
        margin-block-end: var(--spacer-3);
    }

    &__paragraph-html {
        @extend %html-format;
    }
}
