@use "src/styles" as *;

.space-overview-page {

    &__block-renderer {
        margin-block-start: var(--spacer-7);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-8);
        }
    }
}
