@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../../typography/roboto/roboto-light.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../../typography/roboto/roboto-regular.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../../typography/roboto/roboto-medium.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../../typography/roboto/roboto-bold.woff2") format("woff2");
}
