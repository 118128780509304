.brand-carousel-items {
    display: inline-flex;
    align-items: center;
    animation: 20s horizontal-scroll linear infinite;

    &__link {
        min-width: 12rem;
        margin-inline: var(--spacer-5);
        filter: grayscale(100%);
        transition: filter .1s ease-in-out;

        &:hover,
        &:focus {
            filter: grayscale(0%);
        }
    }
}
