@use "src/styles" as *;

.top-navbar-usp {
    display: flex;
    align-items: center;
    gap: var(--spacer-2);
    position: relative;
    width: auto;

    &__link {

        &::after {
            @extend %area-extend;

            content: "";
        }
    }

    &__label {
        line-height: 1;
    }

    &__check {
        height: 1rem;
    }

    &__star-list {
        display: flex;
        align-items: center;
        gap: var(--spacer-1);
        margin-inline-start: var(--spacer-3);
    }

    &__star-item {
        @extend %flex-align-center;

        list-style-type: none;
    }

    &__star {
        height: 1rem;
        width: 1rem;
    }

    &__star-path {

        &--is-filled {
            fill: currentColor;
        }
    }
}
