@use "src/styles" as *;

.password-input {
    display: block;
    width: fit-content;

    &__field-wrapper {
        display: flex;
        position: relative;
    }

    &__field {
        border-start-end-radius: 0;
        border-end-end-radius: 0;
        border-inline-end: none;
    }

    &__visibility-button {
        @extend %button--is-outline;

        color: var(--c-black);
        padding-block: var(--spacer-2);
        padding-inline: var(--spacer-3);
        border-start-start-radius: 0;
        border-end-start-radius: 0;
        border: var(--input-border);
        border-inline-start: unset;
    }

    &__visibility-icon {
        @include square(1.5rem);
    }
}
