@font-face {
    font-family: "Built Titling";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("../../typography/built-titling/built-titling-extra-light.woff2") format("woff2");
}

@font-face {
    font-family: "Built Titling";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("../../typography/built-titling/built-titling-extra-light-italic.woff2") format("woff2");
}

@font-face {
    font-family: "Built Titling";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("../../typography/built-titling/built-titling-light.woff2") format("woff2");
}

@font-face {
    font-family: "Built Titling";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("../../typography/built-titling/built-titling-light-italic.woff2") format("woff2");
}

@font-face {
    font-family: "Built Titling";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../../typography/built-titling/built-titling-regular.woff2") format("woff2");
}

@font-face {
    font-family: "Built Titling";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("../../typography/built-titling/built-titling-regular-italic.woff2") format("woff2");
}

@font-face {
    font-family: "Built Titling";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../../typography/built-titling/built-titling-semi-bold.woff2") format("woff2");
}

@font-face {
    font-family: "Built Titling";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../../typography/built-titling/built-titling-semi-bold-italic.woff2") format("woff2");
}

@font-face {
    font-family: "Built Titling";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../../typography/built-titling/built-titling-bold.woff2") format("woff2");
}

@font-face {
    font-family: "Built Titling";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("../../typography/built-titling/built-titling-bold-italic.woff2") format("woff2");
}
