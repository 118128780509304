@use "src/styles" as *;

.address-form {

    &__item {
        padding-block: var(--spacer-3);
        padding-inline: var(--spacer-4);
        background-color: var(--c-background);
    }

    &__type {
        display: block;
        margin-block-end: var(--spacer-3);
        font-size: .75rem;
        text-transform: uppercase;
    }

    &__input-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: var(--spacer-3);
        width: fit-content;
        margin-block-end: var(--spacer-2);
    }

    &__country-select {
        width: fit-content;
    }

    &__buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: var(--spacer-3);
        margin-block-start: var(--spacer-3);
    }

    &__close-button {
        @extend %button--is-outline;

        color: var(--c-green);
    }
}
