@use "src/styles" as *;

.number-input-with-controls {
    display: block;
    width: fit-content;

    &__field-wrapper {
        display: flex;
    }

    &__field {
        -moz-appearance: textfield;
        height: 100%;
        padding-block: 0;
        padding-inline: var(--spacer-2);
        font-size: 1.125rem;
        font-weight: var(--fw-regular);
        border-radius: 0;
        border-inline: none;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }

        &--has-error {
            padding-inline-end: var(--spacer-5);
        }
    }

    &__control-button {
        @extend %button--is-outline;

        position: relative;
        z-index: 1;
        height: 100%;
        padding-block: var(--spacer-2);
        padding-inline: var(--spacer-3);
        color: currentColor;
        border-radius: 0;
        border: var(--input-border);

        &--minus {
            order: -1;
            border-inline-end: none;
            border-start-start-radius: var(--border-radius);
            border-end-start-radius: var(--border-radius);
        }

        &--plus {
            border-inline-start: none;
            border-start-end-radius: var(--border-radius);
            border-end-end-radius: var(--border-radius);
        }
    }

    &__control-button-icon {
        width: 1rem;
        height: 1rem;
    }
}
