@use "src/styles" as *;

.header-with-intro-skeletons {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: var(--spacer-5);
    height: 100%;
    padding-block: var(--spacer-5);
    border-block-end: 1px solid var(--c-black);

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        grid-template-columns: repeat(2, 1fr);
        gap: 0;
    }

    &__heading {
        height: 3rem;
    }

    &__description {
        padding-inline-start: var(--spacer-5);
        padding-block-start: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            padding-block-start: unset;
        }
    }

    &__description-line {
        margin-block-end: var(--spacer-3);
    };
}