@use "src/styles" as *;

.product-detail-skeletons {

    &__content {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: var(--spacer-4);
        margin-block-end: var(--spacer-5);

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__images {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: var(--spacer-4);

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__image {
        width: 25rem;
        height: 25rem;
    }

    &__sidebar {
        width: 100%;
        height: 100%;
    }
}