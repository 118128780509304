.store-detail-renderer {
    min-height: unset;

    &__header {
        margin-block-start: var(--spacer-5);
    }

    &__description {
        margin-block-start: var(--spacer-5);
    }

    &__block-renderer {
        margin-block-start: var(--spacer-5);
    }
}
