@use "src/styles" as *;

.dialog-content {

    &__header {
        display: flex;
        align-items: center;
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(2.5rem);

        min-width: 2.5rem;
        margin-inline-end: var(--spacer-3);
        color: var(--c-white);
        background-color: var(--c-grey);
    }

    &__title {
        @extend %heading-2;
    }

    &__header + &__content-wrapper {
        margin-block-start: var(--spacer-5);
    }
}
