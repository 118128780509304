@use "src/styles" as *;

.header-with-intro {

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-4);
        padding-block: var(--spacer-5);
        border-block-end: 1px solid var(--c-black);

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            gap: var(--spacer-8);
            padding-block: var(--spacer-6);
        }
    }

    &__heading {
        @extend %heading-2;

        width: 100%;
    }

    &__intro {
        font-family: var(--f-elegant);
        font-size: 1.5rem;
        font-weight: var(--fw-regular);
        line-height: 1.25;
    }
}
