@import "src/styles";

.recaptcha-disclaimer {
    @extend %html-format;

    p {
        font-size: .75rem;
    }

    a {
        text-decoration: underline;
        color: currentColor;
    }
}
