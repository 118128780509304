.slider-examples {

    &__slider {

        & + & {
            margin-block-start: var(--spacer-8);
        }
    }

    &__slider-product {
        height: 100%;
    }
}
