@use "src/styles" as *;

.category-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &__wayfinding-wrapper {
        width: 100%;
    }

    &__wayfinding-icon-path {
        fill: rgba(var(--c-white-rgb), .25);
    }

    &__image-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 12.5rem;
        overflow: hidden;
        background-color: var(--c-green-25);
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__image {
        object-fit: cover;
    }

    &__text-wrapper {
        position: relative;
        z-index: 10;
    }

    &__title {
        @extend %heading-5;

        margin-block-start: var(--spacer-4);
    }
}
