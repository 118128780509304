@use "src/styles" as *;

.wayfinding-direction-button {
    position: relative;

    &__icon-path {
        fill: rgba(var(--c-white-rgb), .25);
    }

    &__button {
        @extend %area-extend;
        @extend %button--no-background;

        &:disabled {
            cursor: none;
            background-color: transparent;
        }

        &::after {
            content: unset;
        }
    }

    &__button-label {
        @extend %area-extend;

        display: none;

        @include touch-screen {
            display: block;
        }
    }
}
