@use "src/styles" as *;

.sort-input {

    &__input-label-wrapper {
        display: flex;
        align-items: baseline;
        gap: var(--spacer-2);
        position: relative;
    }

    &__input-label {
        display: none;
        margin-block-end: 0;

        @include for-size(tablet-portrait-up) {
            display: block;
        }
    }

    .select {

        &__menu-list {
            max-height: 22rem !important;
        }

        &__indicators {
            padding-inline-end: 0;
            transition: scale .1s ease;
        }

        &__control {
            background-color: transparent !important;
            padding-inline-end: var(--spacer-2);
            border: 0 !important;
            width: max-content;
            cursor: pointer;

            &--menu-is-open {

                .select__indicators {
                    scale: 1 -1;
                }
            }
        }

        // Needs to be placed here to overwrite the styling
        &:focus-within .select__control {
            background-color: var(--c-shade) !important;
        }

        &__placeholder {
            color: var(--c-black);
            opacity: 1;
        }

        &__menu {
            border: 0;
            border-radius: .25rem;
            background-color: var(--c-grey-light);
            min-width: max-content;
            left: 50%;
            transform: translateX(-50%);
        }

        &__value-container {
            padding: 0 var(--spacer-2) 0 var(--spacer-2) !important;
        }

        &__option {
            padding: var(--spacer-2) var(--spacer-3);

            &--is-focused {
                color: unset !important;
                background-color: var(--c-green-25) !important;
                cursor: pointer;
            }
        }
    }
}
