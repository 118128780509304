.ssr-blog-categories {

    &__list {
        display: flex;
        justify-content: center;
        gap: var(--spacer-5);
        max-width: var(--wrapper-width);
        margin-inline: auto;
        padding-inline: var(--wrapper-padding);
        overflow: auto;
        font-size: 2rem;
    }

    &__item {
        font-family: var(--f-display);
        font-weight: var(--fw-bold);
        line-height: 1.25;
        text-transform: uppercase;
        color: var(--c-green-dark);
    }
}
