@use "src/styles" as *;

.block-renderer {

    &__block {

        & + & {
            margin-block-start: var(--spacer-6);

            @include for-size(tablet-portrait-up) {
                margin-block-start: var(--spacer-7);
            }
        }
    }
}
