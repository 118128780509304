@use "src/styles" as *;

.faq-item {
    list-style-type: none;

    &__accordion {
        width: 100%;
        padding-inline-start: unset;
    }

    &__summary {
        padding-block: var(--spacer-3);

        @include for-size(tablet-portrait-up) {
            padding-inline-start: var(--spacer-3);
        }
    }

    &__content {
        padding-block: var(--spacer-3) var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            padding-inline-start: var(--spacer-3);
        }
    }
}
