@use "src/styles" as *;

.pagination {
    display: flex;
    align-items: center;
    gap: var(--spacer-2);

    &__direction-button {
        @extend %button--no-background;

        padding: var(--spacer-3);
        color: currentColor;
    }

    &__direction-icon {
        height: 1rem;
    }

    &__list {
        display: flex;
        align-items: center;
    }
}
