@use "src/styles" as *;

.checkout {
    display: flex;
    flex-direction: column;
    margin-block-end: var(--spacer-8);

    &__wrapper {

        &--has-grid-layout {
            display: grid;
            grid-template-columns: 1fr;
            gap: var(--spacer-5);

            @include for-size(tablet-portrait-up) {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    &__cart {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: var(--spacer-5);
        background-color: var(--c-white);
    }

    &__cart-heading {
        @extend %heading-6;

        margin-block-end: var(--spacer-5);
    }

    &__cart-products-wrapper {
        margin-block-end: var(--spacer-6);
        border-block-end: 1px solid var(--c-green);
    }

    &__cart-product {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include for-size(laptop-up) {
            flex-direction: row;
        }
    }

    &__cart-prices-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-4);
    }

    &__cart-price-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-of-type) {
            border-block-end: 1px solid var(--c-green-50);
            padding-block-end: var(--spacer-2);
        }
    }

    &__cart-free-shipping {
        color: var(--c-sale);
    }

    &__cart-price-label-total,
    &__cart-price-total {
        margin-block-end: var(--spacer-5);
        font-size: 2rem;
        font-weight: var(--fw-bold);
        font-family: var(--f-display);
    }
}
