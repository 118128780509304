@use "src/styles" as *;

.slider-section-skeletons {

    &__title-wrapper {
        display: flex;
        align-items: baseline;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__title {
        max-width: 30rem;
        height: 2rem;
        margin-inline-end: var(--spacer-3);
        margin-block-end: var(--spacer-3);

        @include for-size(tablet-portrait-up) {
            height: 4rem;
        }
    }

    &__line {
        width: 100%;
    }

    &__content {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        gap: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            grid-template-columns: repeat(2, 1fr);
            gap: 0;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--spacer-4);
        margin-block-start: var(--spacer-4);

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    &__item {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            width: 20rem;
            height: 25%;
            padding-inline-end: var(--spacer-5);
        }
    }

    &__item-image {
        @extend %skeleton-fade;

        width: 100%;
        aspect-ratio: 1;
        background-color: var(--c-green-25);
    }

    &__item-title {
        height: 2rem;
        margin-block: var(--spacer-3);
    }
}