@use "src/styles" as *;

.brand-overview-renderer {

    &__carousel {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: block;
        }
    }
}
