@use "src/styles" as *;

.blog-overview-page {

    &__categories {
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-8);
        }
    }

    &__blog-overview {
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-7);
        }
    }

    &__block-renderer {
        margin-block-start: var(--spacer-5);
    }
}
