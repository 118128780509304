@use "src/styles" as *;

.product-card-list-skeletons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacer-4);
    margin-block-start: var(--spacer-4);

    @include for-size(tiny-screen-only) {
        grid-template-columns: 1fr;
    }

    @include for-size(tablet-landscape-up) {
        grid-template-columns: repeat(4, 1fr);
    }

    &__list-item {

        @include for-size(tablet-portrait-up) {
            padding-inline-end: var(--spacer-5);
        }
    }

    &__image {
        @extend %skeleton-fade;

        width: 100%;
        aspect-ratio: 1;
        background-color: var(--c-green-25);
    }

    &__meta-wrapper {
        max-width: 20rem;
    }

    &__meta {
        margin-block-start: var(--spacer-3);
        font-size: .875rem;
    }

    &__title {
        margin-block-start: var(--spacer-4);
        font-size: 1.75rem;
    }

    &__button-wrapper {
        width: 12rem;
    }

    &__button {
        margin-block-start: var(--spacer-4);
    }
}
