.icon {
    min-height: 1rem;
    fill: currentColor;
    stroke-width: 6px;

    &__stroke,
    &__box-stroke {
        fill: none;
        stroke: currentColor;
        stroke-width: inherit;
    }

    &__box-stroke {
        color: var(--c-white);

        &--rounded {
            stroke-linecap: round;
        }
    }
}
