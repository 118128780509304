@use "src/styles" as *;

$component: "urgent-banner";

.#{$component} {
    --c-urgent-banner-text: var(--c-white);
    --c-urgent-banner-background: var(--c-green-dark);

    width: 100%;
    max-height: 0;
    overflow: clip;
    font-size: .875rem;
    color: var(--c-urgent-banner-text);
    background-color: var(--c-urgent-banner-background);
    transition: max-height .3s var(--ease-out-strong);

    &--is-revealed {
        max-height: var(--urgent-banner-height);

        .#{$component}__content-wrapper {
            translate: 0 0;
        }
    }

    &__content-wrapper {
        position: relative;
        transition: translate .3s var(--ease-out-strong);
        translate: 0 -100%;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-block: var(--spacer-2);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            gap: var(--spacer-2);
        }
    }

    &__link {
        font-weight: var(--fw-bold);
    }

    &__close-button {
        @extend %button--no-background;

        position: absolute;
        right: var(--spacer-4);
        padding: 0;

        &::after {
            content: unset;
        }

        @include for-size(tablet-portrait-up) {
            right: var(--spacer-5);
        }
    }
}
