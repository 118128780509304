@forward "helpers";
@forward "extends";
@forward "animations";
@forward "variables";
@forward "typography";

@use "helpers/mixins" as *;

* {
    box-sizing: border-box;
}

:focus:not(:focus-visible) {
    outline: none;
}

html {
    font-size: 100%;
    font-variant-ligatures: none;
    interpolate-size: allow-keywords;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--f-primary);
    font-weight: var(--fw-light);
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--c-black);
    background-color: var(--c-background);
}

h1, h2, h3, h4, h5, h6 {
    @extend %heading;

    margin: 0;
}

p {
    margin: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    color: currentColor;
    text-decoration: none;
}

noscript {
    display: flex;
    justify-content: center;
    position: fixed;
    inset-block-end: 0;
    inset-inline-start: 0;
    z-index: 900;
    width: 100%;
    padding: var(--spacer-3);
    font-weight: var(--fw-medium);
    color: var(--c-white);
    background-color: var(--c-black);
}

#app-root {
    position: relative;
    z-index: 10;
}

#portal-root {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
}

.grecaptcha-badge {
    display: none !important;
    visibility: hidden;
}
