@use "src/styles" as *;

.guest-wishlist-page {

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: var(--spacer-5);
        gap: var(--spacer-5);
        background-color: var(--c-white);
    }

    &__login-button {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            width: 370px;
        }
    }

    &__create-account {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: var(--spacer-1);
    }

    &__no-account-label {
        color: var(--c-black);
    }

    &__no-account-button {
        @extend %button--link;

        color: var(--c-black);
        text-decoration: underline;
    }
}
