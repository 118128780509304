.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--spacer-1);
    margin-inline: 0;
    font-family: var(--f-primary);
    font-weight: var(--fw-regular);

    &__item {
        color: var(--c-green-dark);
        white-space: nowrap;

        &:last-child {
            color: var(--c-black);
        }
    }
}
