@use "src/styles" as *;

.account-dashboard-config {

    &__tabs {
        position: relative;
        margin-block: var(--spacer-4);
        overflow-x: auto;

        &::after {
            content: "";
            position: absolute;
            z-index: 10;
            inset-block-end: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 2px;
            background-color: var(--c-green-75);
        }
    }

    &__tabs-item-label {
        font-family: var(--f-primary);
        font-weight: var(--fw-regular);
        font-size: 1.125rem;
        line-height: .88;
        text-transform: initial;
        color: var(--c-black);
    }
}
