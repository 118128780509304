@use "src/styles" as *;

.make-appointment-section {
    margin: var(--spacer-7) 0;

    @include for-size(tablet-landscape-up) {
        display: grid;
        grid-template-columns: 2fr 3fr;
        margin: var(--spacer-8);
    }

    &__info {
        padding: var(--spacer-6) var(--spacer-5);
        background-color: var(--c-green-50);

        @include for-size(tablet-landscape-up) {
            padding: var(--spacer-6);
        }

        &-title {
            color: var(--c-green-dark);
        }

        &-description {
            margin: var(--spacer-4) 0;
        }

        &-sub-title {
            color: var(--c-green-dark);
            font-weight: var(--fw-bold);
        }

        &__contact-info {
            display: flex;
            flex-direction: row;
            gap: .5rem;
            margin: var(--spacer-5) 0;

            &-icon {
                height: 1.5rem;
            }

            &-name {
                font-weight: var(--fw-regular);
            }

            &-address {
                font-weight: var(--fw-light);
            }

            &-opening-time {
                font-weight: var(--fw-light);
                color: var(--c-green-dark);
            }
        }
    }

    &__form {
        padding: var(--spacer-6) var(--spacer-5);
        background-color: var(--c-white);

        @include for-size(tablet-landscape-up) {
            padding: var(--spacer-6);
        }

        &-title {
            font-size: 1.5rem;
            margin-bottom: var(--spacer-4);

            &:last-of-type {
                margin-top: var(--spacer-5);
            }
        }

        &-row {
            display: grid;
            grid-template-columns: 1fr 25%;
            grid-gap: var(--spacer-3);

            & > :last-of-type {
                grid-column: 1 / span 2;
            }

            @include for-size(tablet-landscape-up) {
                grid-gap: var(--spacer-4);
                grid-template-columns: 1fr 4.2rem 1fr;

                & > :last-of-type {
                    grid-column: unset;
                }
            }
        }

        &-item {
            width: 100%;
        }

        & > * {
            margin-bottom: var(--spacer-4);
        }
    }
}
