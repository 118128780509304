@use "src/styles" as *;

$component: "block-renderer-skeletons";

.#{$component} {

    &__section {

        & + & {
            margin-block-start: var(--spacer-6);

            @include for-size(tablet-portrait-up) {
                margin-block-start: var(--spacer-7);
            }
        }
    }

    &__paragraph {
        margin-block-start: var(--spacer-5);

        &:first-child {
            margin-block-start: 0;
        }

        & + & {
            margin-block-start: var(--spacer-4);
        }
    }

    &__paragraph-line {

        & + & {
            margin-block-start: var(--spacer-2);
        }
    }

    &__text-image {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }

        &:nth-of-type(odd) {
            flex-direction: column;

            @include for-size(tablet-portrait-up) {
                flex-direction: row-reverse;
            }
        }
    }

    &__text-image-text-wrapper,
    &__text-image-image {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            width: 50%;
        }
    }

    &__text-image-text-wrapper {
        padding: var(--spacer-6);
    }

    &__text-image-title {
        font-size: 1.5rem;
    }

    &__text-image-image {
        @extend %skeleton-fade;

        aspect-ratio: 16 / 9;
        background-color: var(--c-green-25);
    }

    &__media-grid {
        display: grid;
        gap: var(--spacer-4);

        &--layout-2 {
            grid-template-columns: 1fr 1fr;
            aspect-ratio: 16 / 9;

            @include for-size(tablet-portrait-up) {
                grid-template-columns: 1fr 1.5fr;
            }

            .#{$component}__media-item-wrapper {
                height: auto;

                &:nth-of-type(2) {
                    aspect-ratio: 5 / 3;
                }
            }
        }

        &--layout-3 {
            grid-template-columns: 1fr 1fr;

            @include for-size(tablet-portrait-up) {
                grid-template-columns: 5fr 2fr 3fr;
                aspect-ratio: 16 / 9;
            }

            .#{$component}__media-item-wrapper {

                &:nth-of-type(1) {
                    aspect-ratio: 1;
                    grid-column: 1 / 3;
                }

                &:nth-of-type(2) {
                    height: auto;
                    aspect-ratio: 1;
                }

                &:nth-of-type(3) {
                    height: auto;
                    aspect-ratio: 2 / 3;
                }

                @include for-size(tablet-portrait-up) {

                    &:nth-of-type(1) {
                        grid-column: unset;
                    }

                    &:nth-of-type(2) {
                        aspect-ratio: 2 / 3;
                    }
                }
            }
        }
    }

    &__media-item-wrapper {
        min-width: 100%;
    }

    &__media-item {
        @extend %skeleton-fade;

        width: 100%;
        height: 100%;
        background-color: var(--c-green-25);
    }

    &__content-columns {
        display: grid;
        justify-items: center;
        grid-template-columns: 1fr;

        &--layout-2 {

            @include for-size(tablet-portrait-up) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &--layout-3 {

            @include for-size(tablet-portrait-up) {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    &__content-columns-column {
        width: 100%;
        margin-block-start: var(--spacer-6);
        padding-block: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            border-inline-end: none;
        }
    }
}
