@use "src/styles" as *;

.video {
    display: flex;
    align-items: center;
    position: relative;

    &:hover &__button,
    &:focus-within:focus-visible &__button,
    &--is-paused &__button {
        opacity: .5;

        &:hover,
        &:focus:focus-visible {
            opacity: 1;
        }
    }

    &__play-button-wrapper {
        @extend %flex-align-center;
        @extend %area-extend;

        z-index: 10;
    }

    &__button {
        transition: opacity .3s ease;
        opacity: 0;

        &:hover,
        &:focus:focus-visible {
            opacity: 1;
        }
    }

    &__controls-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-1);
        position: absolute;
        inset-block-end: var(--spacer-3);
        inset-inline-end: var(--spacer-3);
        z-index: 10;
    }

    &__control-button {
        @include circle(3rem);

        padding: 0;
        color: var(--c-accent);
        background-color: var(--c-white);
    }

    &__element {
        width: 100%;
        height: 100%;
        max-height: 90vh;
        object-fit: contain;
        border-radius: var(--border-radius);
        background-color: var(--c-grey-light);
    }
}
