@use "src/styles" as *;

.stores-overview-page {

    &__title {
        padding-block: var(--spacer-5);
        font-family: var(--f-primary);
        font-size: 1.5rem;
        text-align: center;
    }

    &__block-renderer {
        margin-block-start: var(--spacer-6);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-7);
        }
    }
}
