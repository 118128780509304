@use "src/styles" as *;

.checkout-navigation-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: var(--fw-regular);
    color: var(--c-black);

    &__link {
        display: grid;
        place-items: center;
        position: relative;

        &::before {
            @include circle(.25rem);

            content: "";
            margin-block-end: var(--spacer-3);
            padding: var(--spacer-1);
            color: var(--c-active);
            border: 4px solid;
            border-color: var(--c-green-dark);
            background-color: var(--c-background);

            @include for-size(tablet-landscape-up) {
                @include circle(.375rem);
            }
        }

        &--is-active {

            &::after {
                content: "";
                height: var(--line-thickness);
                position: absolute;
                right: 50%;
                top: calc((var(--circle-width) - var(--line-thickness)) / 2);
                width: 100vw;
                background: var(--c-green-dark);
            }
        }

        &--is-active,
        &--is-done {

            &::before {
                background-color: var(--c-green-dark);
            }
        }
    }

    &__label {
        font-size: .875rem;

        @include for-size(tablet-landscape-up) {
            font-size: 1rem;
        }
    }
}
