@use "src/styles" as *;

.header {
    width: 100%;

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: stretch;
            gap: unset;
        }
    }

    &__media-wrapper,
    &__text-wrapper {
        width: 100%;
    }

    &__media-wrapper {
        display: flex;
        position: relative;
        min-height: 27rem;
        max-height: 80dvh;

        @include for-size(tablet-portrait-up) {
            margin-inline-end: var(--spacer-5);
            min-height: 45rem;
        }
    }

    &__picture {
        @extend %area-extend;

        width: 100%;
        height: 100%;

        @include for-size(tablet-portrait-up) {
            position: static;
        }
    }

    &__image {
        object-fit: cover;
    }

    &__video-theatre {
        position: absolute;
    }

    &__text-wrapper {
        padding-inline-start: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            padding-block: var(--spacer-6) var(--spacer-5);
        }
    }

    &__text-inner-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-inline: var(--spacer-4);
        border-inline-start: 1px solid var(--c-black);

        @include for-size(tablet-portrait-up) {
            padding-inline: var(--spacer-5) var(--spacer-4);
        }
    }

    &__back-button {
        @extend %button--no-background;

        margin-block-end: var(--spacer-4);
        padding-block: 0;
        padding-inline: var(--spacer-1);
        color: var(--c-black);
    }

    &__back-button-label {
        gap: var(--spacer-2);
        font-weight: var(--fw-regular);
    }

    &__back-button-icon {
        height: 1rem;
    }

    &__title-wrapper {
        @extend %heading-2;

        margin-block-end: auto;
    }

    &__title {
        @extend %heading-2;

        text-wrap: balance;
        color: var(--c-accent);
    }

    &__sub-title {
        @extend %heading-3;

        margin-block-start: var(--spacer-3);
        font-family: var(--f-script);
        font-weight: var(--fw-regular);
        font-size: .75em;
        text-transform: uppercase;
        line-height: 1;
    }

    &__intro {
        @extend %html-format;

        margin-block-start: var(--spacer-7);
        font-family: var(--f-elegant);
        font-style: italic;
        font-size: 1.25rem;
        line-height: 2rem;

        @include for-size(tablet-portrait-up) {
            font-size: 1.5rem;
        }
    }
}
