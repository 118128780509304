.checkbox-list {
    width: fit-content;

    &__label {
        justify-content: flex-start;
    }

    &__option-wrapper {

        &--has-error {
            padding-inline-start: var(--spacer-3);
            border-inline-start: 2px solid var(--c-error);
        }
    }

    &__item {
        padding-block: var(--spacer-2);

        &:first-of-type {
            padding-block-start: 0;
        }

        &:last-of-type {
            padding-block-end: 0;
        }
    }
}
