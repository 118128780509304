.address {
    font-style: normal;
    white-space: pre;

    &__label {
        display: block;
        font-size: .75rem;
        text-transform: uppercase;
        margin-block-end: var(--spacer-2);
    }
}
