@use "src/styles" as *;

.registration-page {

    &__wrapper {
        --wrapper-padding: 0;

        display: flex;
        flex-direction: column;
        gap: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            --wrapper-padding: var(--spacer-5);

            flex-direction: row;
        }
    }

    &__section {
        margin-block: var(--spacer-5);
    }

    &__service-section {
        padding-block: var(--spacer-7);
        background-color: var(--c-white);
    }

    &__sub-footer {
        margin-block-start: 0;
    }
}
