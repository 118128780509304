@use "src/styles" as *;

$component: "content-column";

.#{$component} {

    &__title-html,
    &__paragraph-html {
        @extend %html-format;
    }

    &__paragraph-html {
        margin-block-start: var(--spacer-5);

        & + .#{$component}__image {
            margin-block-start: var(--spacer-5);
        }
    }

    &__image {
        width: 100%;
    }
}
