@use "src/styles" as *;

$component: "radio";

.#{$component} {
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: 1rem;
    font-weight: var(--fw-regular);
    cursor: pointer;

    &:hover,
    &:focus-within {

        .#{$component}__box::after {
            border: 1px solid var(--c-accent);
            background-color: transparent;
            scale: 1;
            opacity: .5;
        }
    }

    &--is-disabled {
        color: var(--c-charcoal);
        cursor: not-allowed;

        .#{$component}__box {
            background-color: var(--c-shade);

            &::after {
                content: unset;
            }
        }
    }

    &__input {
        position: absolute;
        opacity: 0;

        &:checked ~ .#{$component}__box {

            &::after {
                background-color: var(--c-accent);
                scale: 1;
                opacity: 1;
            }
        }
    }

    &__box {
        @extend %flex-align-center;
        @include circle(1.5rem);

        min-width: 1.5rem;
        border: 1px solid var(--c-green-75);
        background-color: var(--c-white);

        &::after {
            @include circle(1rem);

            content: "";
            transition: scale calc(.1s * var(--transition-multiplier)) ease, opacity .1s ease;
            scale: 0;
            opacity: 0;

            @include prefers-reduced-motion {
                scale: 1;
            }
        }
    }

    &__image {
        height: var(--spacer-4);
    }

    &__image,
    &__label {
        margin-inline-start: var(--spacer-3);
    }
}
