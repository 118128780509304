@use "src/styles" as *;

.order-overview {

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--spacer-4);
        width: 100%;
        margin-block-start: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include for-size(laptop-up) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__pagination-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-block-start: var(--spacer-4);
    }
}
