.instant-search-page-hit-skeletons {
    display: flex;
    flex-direction: column;

    &__tag-wrapper {
        width: 6rem;
    }

    &__title-wrapper {
        width: 16rem;
    }

    &__tag,
    &__title {
        margin-block-start: var(--spacer-3);
        font-size: .875rem;
    }

    &__description {
        margin-block-start: var(--spacer-4);
        font-size: 2.75rem;
    }
}
