@use "src/styles" as *;

.featured-products-vertical-title {

    &__products-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        gap: var(--spacer-4);
    }

    &__product-wrapper {
        display: flex;
        flex: 1 0 75%;

        &:nth-of-type(2),
        &:nth-of-type(3) {
            flex: 1 0 33%;
        }

        @include for-size(tablet-portrait-up) {
            flex: 1 0 50%;

            &:nth-of-type(2),
            &:nth-of-type(3) {
                flex: 1 0 20%;
            }
        }
    }

    &__product {
        width: 100%;
    }

    &__title {
        @extend %heading-2;

        align-self: flex-start;
        padding-block-end: var(--spacer-4); // end instead of start because text is rotated 180 degrees
        writing-mode: vertical-lr;
        color: var(--c-green-dark);
        rotate: -180deg;

        @include for-size(tablet-portrait-up) {
            padding-block-start: min(var(--spacer-8), 10%); // start instead of end because text is rotated 180 degrees
        }
    }

    &__footer {
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-4);
        }
    }
}
