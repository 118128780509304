@use "src/styles" as *;

.faq-list {

    &__search-input {
        width: 100%;
        margin-block-end: var(--spacer-5);
    }

    &__search-input-field {
        border: unset;
        border-block-end: 2px solid var(--c-black);
        background-color: var(--c-green-50);

        @include for-size(tablet-landscape-up) {
            font-family: var(--f-display);
            font-size: 1.75rem;
            line-height: 1.14;
        }

        &:focus {
            border-color: var(--c-green-dark);
        }

        &:not(:focus)::placeholder {
            color: var(--c-black);
            opacity: 1;
        }
    }

    &__no-results {
        margin-block-start: var(--spacer-3);
    }

    &__category {
        padding-inline: 0;
    }

    &__category-item:last-child &__category-item-accordion {
        border-block-end: none;
    }

    &__category-summary {
        font-size: 1.125rem;
    }

    &__category-content {
        padding-block: 0;
    }
}
