:root {
    // Base colors
    --c-black-rgb:              44, 44, 48;
    --c-black:                  rgb(var(--c-black-rgb));

    --c-white-rgb:              255, 255, 255;
    --c-white:                  rgb(var(--c-white-rgb));

    --c-green-dark-rgb:         130, 132, 116;
    --c-green-dark:             rgb(var(--c-green-dark-rgb));

    --c-green-rgb:              167, 171, 159;
    --c-green:                  rgb(var(--c-green-rgb));

    --c-green-75-rgb:           189, 192, 183;
    --c-green-75:               rgb(var(--c-green-75-rgb));

    --c-green-50-rgb:           211, 213, 207;
    --c-green-50:               rgb(var(--c-green-50-rgb));

    --c-green-25-rgb:           233, 234, 231;
    --c-green-25:               rgb(var(--c-green-25-rgb));

    --c-grey-light-rgb:         241, 241, 241;
    --c-grey-light:             rgb(var(--c-grey-light-rgb));

    // Background
    --c-background-rgb:         243, 243, 243;
    --c-background:             rgb(var(--c-background-rgb));

    // Support colors
    --c-sand-soft-rgb:          227, 222, 213;
    --c-sand-soft:              rgb(var(--c-sand-soft-rgb));

    --c-sand-warm-rgb:          202, 186, 158;
    --c-sand-warm:              rgb(var(--c-sand-warm-rgb));

    --c-ocher-soft-rgb:         179, 153, 94;
    --c-ocher-soft:             rgb(var(--c-ocher-soft-rgb));

    --c-caramel-rgb:            146, 113, 69;
    --c-caramel:                rgb(var(--c-caramel-rgb));

    --c-chocolate-rgb:          107, 77, 49;
    --c-chocolate:              rgb(var(--c-chocolate-rgb));

    --c-khaki-rgb:              171, 163, 134;
    --c-khaki:                  rgb(var(--c-khaki-rgb));

    --c-cement-rgb:             216, 212, 208;
    --c-cement:                 rgb(var(--c-cement-rgb));

    --c-turquoise-rgb:          155, 182, 187;
    --c-turquoise:              rgb(var(--c-turquoise-rgb));

    --c-charcoal-rgb:           103, 106, 112;
    --c-charcoal:               rgb(var(--c-charcoal-rgb));

    --c-green-light-rgb:        178, 181, 128;
    --c-green-light:            rgb(var(--c-green-light-rgb));

    --c-green-garage-rgb:       139, 143, 107;
    --c-green-garage:           rgb(var(--c-green-garage-rgb));

    --c-rust-rgb:               171, 84, 44;
    --c-rust:                   rgb(var(--c-rust-rgb));

    // Category colors
    --c-outdoor-1-rgb:          114, 128, 103;
    --c-outdoor-1:              rgb(var(--c-outdoor-1-rgb));

    --c-outdoor-2-rgb:          187, 182, 96;
    --c-outdoor-2:              rgb(var(--c-outdoor-2-rgb));

    --c-favorites-brown-rgb:    68, 40, 0;
    --c-favorites-brown:        rgb(var(--c-favorites-brown-rgb));

    --c-favorites-blue-rgb:     165, 189, 188;
    --c-favorites-blue:         rgb(var(--c-favorites-blue-rgb));

    --c-favorites-pink-rgb:     231, 157, 175;
    --c-favorites-pink:         rgb(var(--c-favorites-pink-rgb));

    --c-goods-1-rgb:            137, 119, 58;
    --c-goods-1:                rgb(var(--c-goods-1-rgb));

    --c-goods-2-rgb:            161, 182, 189;
    --c-goods-2:                rgb(var(--c-goods-2-rgb));

    --c-deals-rgb:              193, 174, 70;
    --c-deals:                  rgb(var(--c-deals-rgb));

    --c-sale-rgb:               193, 91, 50;
    --c-sale:                   rgb(var(--c-sale-rgb));

    // UI colors
    --c-success-rgb:            3, 159, 0;
    --c-success:                rgb(var(--c-success-rgb));

    --c-warning-rgb:            244, 147, 0;
    --c-warning:                rgb(var(--c-warning-rgb));

    --c-error-rgb:              139, 0, 0;
    --c-error:                  rgb(var(--c-error-rgb));

    --c-box-shadow-rgb:         30, 53, 81;
    --c-box-shadow:             rgb(var(--c-box-shadow-rgb));

    // Theme colors
    --c-primary-rgb:            var(--c-green-dark-rgb);
    --c-primary:                rgb(var(--c-primary-rgb));

    --c-accent:                 var(--c-primary);
    --c-shade:                  rgba(var(--c-black-rgb), .16);

    // Product variant colors
    --c-pv-anthracite:          #4a4a4a;
    --c-pv-beige:               #e6d8be;
    --c-pv-blue:                #2b4f9e;
    --c-pv-brown:               #75523c;
    --c-pv-cognac:              #a35200;
    --c-pv-yellow:              #fff200;
    --c-pv-gold:                #fabd43;
    --c-pv-grey:                #adadad;
    --c-pv-green:               #569602;
    --c-pv-multicolor:          conic-gradient(hsl(0deg, 100%, 50%), hsl(60deg, 100%, 50%), hsl(120deg, 100%, 50%), hsl(180deg, 100%, 50%), hsl(240deg, 100%, 50%), hsl(300deg, 100%, 50%), hsl(360deg, 100%, 50%));
    --c-pv-orange:              #fa6604;
    --c-pv-purple:              #7030a0;
    --c-pv-red:                 #bf1717;
    --c-pv-pink:                #ff75bc;
    --c-pv-taupe:               #9c9281;
    --c-pv-transparent:         #ffffff;
    --c-pv-turquoise:           #37bfaf;
    --c-pv-white:               #ffffff;
    --c-pv-silver:              #d0d0d0;
    --c-pv-black:               #000000;
    --c-pv-no-data:             repeating-conic-gradient(black 0% 25%, transparent 0% 50%) 50% / .5rem .5rem;

    // Typography
    --f-primary:                "Roboto", system-ui, sans-serif;
    --f-display:                "Built Titling", system-ui, sans-serif;
    --f-elegant:                "quiche-sans", system-ui, fantasy;
    --f-script:                 "Hot Mess", system-ui, cursive;

    --fw-light:                 300;
    --fw-regular:               400;
    --fw-medium:                500;
    --fw-bold:                  700;

    // Miscellaneous
    --wrapper-width:            90rem;
    --wrapper-padding:          var(--spacer-5);
    --nested-wrapper-width:     64rem;

    --menu-height:              5rem;
    --submenu-height:           0;

    --scrollbar-width:          calc(100vw - 100%);

    --border-radius:            .125rem;
    --box-shadow:               0 4px 4px 0 rgba(var(--c-box-shadow-rgb), .10);

    --transition-multiplier:    1;
    --ease-out-strong:          cubic-bezier(.25, .75, .5, 1);

    // Spacers
    --spacer: 1rem; // Set the default spacer value
    --spacer-1: calc(var(--spacer) * 0.25); // 4px // 0.25rem
    --spacer-2: calc(var(--spacer) * .5); // 8px // 0.5rem
    --spacer-3: var(--spacer); // 16px // 1rem
    --spacer-4: calc(var(--spacer) * 1.5); // 24px // 1.5rem
    --spacer-5: calc(var(--spacer) * 2); // 32px // 2rem
    --spacer-6: calc(var(--spacer) * 4); // 64px // 4rem
    --spacer-7: calc(var(--spacer) * 5); // 80px // 5rem
    --spacer-8: calc(var(--spacer) * 7.5); // 120px // 7.5rem

    @media (prefers-reduced-motion: reduce) {
        --transition-multiplier: 0;
    }

    @media (min-width: 768px) {
        --menu-height:      5.5rem;
        --submenu-height:   0;
    }
}

::backdrop {
    --c-background-rgb:         243, 243, 243;
    --c-background:             rgb(var(--c-background-rgb));
}
