%button {
    --button-label-offset: calc(-100% - var(--spacer-5));
    --button-label-position: 0%;
    --c-button-hover: var(--c-shade);

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0;
    padding-block: var(--spacer-3);
    padding-inline: var(--spacer-4);
    overflow: clip;
    font: inherit;
    font-size: 1rem;
    font-weight: var(--fw-medium);
    line-height: 1.5;
    letter-spacing: .32px;
    color: var(--c-white);
    cursor: pointer;
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--c-green-dark);

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: var(--c-button-hover);
        transition: opacity .1s ease;
        opacity: 0;
    }

    &:enabled:hover,
    &:enabled:focus {

        &::after {
            opacity: 1;
        }
    }

    &:disabled {
        color: var(--c-charcoal);
        cursor: not-allowed;
        background-color: var(--c-shade);
    }
}

%button--no-background {
    --c-button-hover: var(--c-green-25);

    background: none;

    &[href]:hover,
    &[href]:focus {
        background: none;
    }

    &:enabled:hover,
    &:enabled:focus {
        background: none;
    }
}

%button--is-outline {
    @extend %button--no-background;

    border: 1px solid currentColor;
}

%button--link {
    @extend %button--no-background;

    padding: 0;
    color: var(--c-green-dark);

    &::after {
        content: unset;
    }
}
