@use "src/styles" as *;

.cart-section {
    display: flex;
    flex-direction: column;
    padding: var(--spacer-5);
    background-color: var(--c-white);

    &__heading {
        @extend %heading-6;

        margin-block-end: var(--spacer-5);
    }

    &__products-wrapper {
        margin-block-end: var(--spacer-5);
    }

    &__product-image {
        @include square(5.5rem);

        min-width: 5.5rem;
    }

    &__prices-wrapper {
        margin-block-end: var(--spacer-5);
    }
}
