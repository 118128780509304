@use "src/styles" as *;

.wayfinding-examples {

    &__item {

        & + & {
            margin-block-start: var(--spacer-4);
        }
    }

    &__progress-wrapper {
        display: flex;
        align-items: center;
        gap: var(--spacer-3);
    }

    &__progress {
        width: 100%;
    }

    &__progress-button-wrapper {
        display: flex;
        gap: var(--spacer-2);
    }

    &__progress-button {
        @extend %button--no-background;

        padding: var(--spacer-2);
        color: var(--c-black);
    }

    &__progress-icon {
        height: 3.75rem;
        stroke-width: 2px;
    }

    &__wrapper {
        width: fit-content;
    }

    &__wrapper-example {
        @extend %flex-align-center;
        @include square(6.5rem);

        border: 1px solid var(--c-black);
        background-color: var(--c-white);
    }
}
