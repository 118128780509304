@use "src/styles" as *;

.order-overview-skeletons {

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--spacer-4);
        width: 100%;
        margin-block-start: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include for-size(laptop-up) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: var(--spacer-3);
        border: 1px solid var(--c-green-50);
    }

    &__product-image-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--spacer-3);
    }

    &__product-image-grid:has(&__product-image-item:only-child) {
        grid-template-columns: 1fr;
    }

    &__product-image {
        @extend %skeleton-fade;

        aspect-ratio: 1;
        background-color: var(--c-green-25);
    }

    &__info-wrapper {
        margin-block-start: var(--spacer-3);
        border-block-start: 1px solid var(--c-green-50);
    }

    &__order-number,
    &__order-date,
    &__order-total {
        margin-block-start: var(--spacer-3);
    }

    &__link-button {
        width: 100%;
        height: 2.5rem;
        margin-block-start: var(--spacer-3);
        border: 1px solid var(--c-green-50);
    }
}
