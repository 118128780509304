@use "src/styles" as *;

.giftcard-balance-page {

    &__form-section {
        padding-block: var(--spacer-6);
        background-color: var(--c-white);
    }

    &__product-list {
        margin-block-start: var(--spacer-5);
    }

    &__block-renderer {
        margin-block-start: var(--spacer-5);
    }
}
