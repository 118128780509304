.product-information-skeletons {

    &__description {
        width: 100%;
        height: 45rem;
        margin-block-end: var(--spacer-5);
    }

    &__brand {
        width: 100%;
        height: 20rem;
    }
}