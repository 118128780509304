@use "src/styles" as *;

.order-detail-renderer-skeletons {

    &__title-wrapper {
        max-width: 32rem;
    }

    &__title {
        height: 1.5rem;
    }

    &__wrapper {
        margin-block-start: var(--spacer-4);

        @include for-size(tablet-landscape-up) {
            display: grid;
            grid-template-columns: 1fr 2fr;
        }
    }

    &__detail-wrapper {

        @include for-size(tablet-landscape-up) {
            padding-inline-start: var(--spacer-4);
            border-inline-start: 1px solid var(--c-green-75);
        }
    }

    &__block {

        & + & {
            margin-block-start: var(--spacer-4);
        }
    }

    &__block-header {
        margin-block-end: var(--spacer-3);
        padding-block-end: var(--spacer-2);
        border-block-end: 1px solid var(--c-green-75);
    }

    &__block-label-wrapper {
        max-width: 12rem;
    }

    &__block-label {
        height: .75rem;
    }

    &__labelled-value {
        max-width: 24rem;

        & + & {
            margin-block-start: var(--spacer-3);
        }
    }

    &__label-wrapper {
        max-width: 12rem;
    }

    &__label {
        height: .875rem;
    }

    &__value {
        margin-block-start: var(--spacer-2);
    }

    &__price-table {
        margin-block-start: var(--spacer-4);
    }

    &__price-table-row,
    &__price-table-footer {
        display: flex;
        justify-content: space-between;
        width: 100%;

        & + & {
            margin-block-start: var(--spacer-2);
        }
    }

    &__price-table-footer {
        margin-block-start: var(--spacer-4);
        padding-block: var(--spacer-3);
        border-block-start: 1px solid var(--c-green-75);
    }

    &__price-table-cell {
        width: 100%;
        max-width: 12rem;

        &:last-child {
            display: flex;
            justify-content: flex-end;
        }
    }
}
