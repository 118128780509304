@use "src/styles" as *;

.checkout-navigation {

    &__list {
        --c-main: var(--c-green-50);
        --c-active: var(--c-white);
        --circle-width: 1.5em;
        --line-thickness: 2px;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0;
        overflow: hidden;
        background-image: linear-gradient(var(--c-main) 0 0);
        background-repeat: no-repeat;
        background-position: 50% calc((var(--circle-width) - var(--line-thickness)) / 2);
        background-size: 100% var(--line-thickness);

        @include for-size(laptop-up) {
            width: 25vw;
            margin-block: var(--spacer-4);
            margin-inline: auto;
        }
    }
}
