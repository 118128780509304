@use "src/styles" as *;

.usp-section {
    --wrapper-width: 72rem;
    --c-usp-background: var(--c-favorites-blue);

    background-color: var(--c-usp-background);

    &__wrapper {
        padding: 0;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: wrap;
        gap: var(--spacer-4);
        padding-block: var(--spacer-6);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--spacer-3);
        font-weight: var(--fw-regular);
        text-align: center;
    }

    &__item-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
}
