@use "src/styles" as *;

.department-overview {

    &__title {
        @extend %heading-3;

        color: var(--c-green-dark);
        text-align: center;
    }

    &__skeletons,
    &__list {
        margin-block-start: var(--spacer-5);
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: var(--spacer-5);
        column-gap: 1px;

        @include for-size(tiny-screen-only) {
            grid-template-columns: 1fr;
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__list-item {

        @include for-size(tablet-portrait-up) {
            padding-inline-end: var(--spacer-5);
        }

        & + & {
            padding-block-start: var(--spacer-5);
            border-block-start: 1px solid var(--c-black);

            @include for-size(tablet-portrait-up) {
                padding-block-start: unset;
                border-block-start: unset;
            }
        }
    }
}
