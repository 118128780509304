@use "src/styles" as *;

.checkout-page {

    &__navigation-wrapper {
        display: flex;
        flex-direction: column;
    }

    &__logo {
        width: 3.5rem;
        height: 3.5rem;

        @include for-size(tablet-portrait-up) {
            align-self: center;
        }
    }

    &__contents-wrapper {
        padding-inline: unset;

        @include for-size(tablet-portrait-up) {
            padding-inline: var(--wrapper-padding);
        }
    }
}
