@use "src/styles" as *;

.participate-form {
    padding: unset;

    @include for-size(tablet-landscape-up) {
        --wrapper-width: 72rem;
    }

    &__form {
        padding: var(--spacer-6) var(--spacer-5);
        background-color: var(--c-white);

        @include for-size(tablet-landscape-up) {
            padding: var(--spacer-6);
            margin: 0 var(--spacer-8);
        }
    }

    &__title {
        font-size: 1.5rem;
        margin-block-end: var(--spacer-4);
    }

    &__group {
        border-block-end: 1px solid var(--c-green-50);
        margin-block-end: var(--spacer-4);
    }

    &__row {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--spacer-3);

        @include for-size(tablet-landscape-up) {
            grid-gap: var(--spacer-4);
            grid-template-columns: 1fr .5fr 1fr;

            &--two {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    &__item {
        width: 100%;
        margin-block-end: var(--spacer-4);
    }

    &__button {
        width: 100%;
    }

    &__recaptcha-disclaimer {
        margin-block-start: var(--spacer-5);
    }
}
