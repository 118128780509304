@use "src/styles" as *;

.mobile-navigation {
    --navigation-transition-duration: .5s;

    &__toggle-button {
        @extend %button--no-background;

        padding: var(--spacer-2);
        color: var(--c-black);
    }

    &__drawer {
        position: absolute;
        inset-block-start: var(--menu-height);
        inset-inline-start: 0;
        overflow: auto;
        width: 100vw;
        height: calc(100dvh - var(--menu-height));
        padding-block: var(--spacer-5);
        pointer-events: none;
        border-block-start: 1px solid var(--c-green-dark);
        background-color: var(--c-background);
        transition: translate calc(.3s * var(--transition-multiplier)) var(--ease-out-strong);
        translate: 100% 0;

        &--is-open {
            @include prevent-body-scroll;

            pointer-events: all;
            translate: 0 0;
        }
    }

    &__navigation {

        &--is-going-forwards {
            --fade-offset-start: -25%;
            --fade-offset-end: 25%;

            animation: navigation-fade var(--navigation-transition-duration) ease;
        }

        &--is-going-backwards {
            --fade-offset-start: 25%;
            --fade-offset-end: -25%;

            animation: navigation-fade var(--navigation-transition-duration) ease;
        }
    }

    &__contents {
        min-height: 100%;
    }

    &__group {
        position: relative;
        margin: 0;
        padding: 0;

        & + & {
            margin-block-start: var(--spacer-2);
            padding-block-start: var(--spacer-2);

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - 2.5rem);
                height: 1px;
                background-color: var(--c-black);
            }
        }

        &:last-of-type {
            margin-block-end: var(--spacer-5);
        }
    }

    &__parent-link-button {
        @extend %button--no-background;

        margin-block-end: var(--spacer-5);
        padding-inline: 0;
        text-transform: uppercase;
        color: var(--c-green-dark);

        &::after {
            content: unset;
        }
    }

    &__parent-link-icon {
        color: var(--c-black);
    }
}
