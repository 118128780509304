@use "src/styles" as *;

.number-input {
    display: block;
    min-width: 8rem;

    &__field-wrapper {
        display: flex;
        align-items: center;
        background: var(--c-white);
        border: 1px solid var(--c-green-75);

        &--reversed {
            flex-direction: row-reverse;
        }
    }

    &__unit {
        padding-inline: var(--spacer-2);
        color: var(--c-green);
    }

    &__field {
        -moz-appearance: textfield;
        height: 100%;
        padding-block: 0;
        padding-block: var(--spacer-2);
        padding-inline: var(--spacer-2);
        font-size: 1.125rem;
        font-weight: var(--fw-regular);
        border: none;
        background-color: transparent;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }

        &--has-error {
            padding-inline-end: var(--spacer-5);
        }
    }
}
