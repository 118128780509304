%input {
    width: 100%;
    margin: 0;
    padding-block: var(--spacer-2);
    padding-inline: var(--spacer-3);
    font: inherit;
    font-weight: var(--fw-regular);
    color: currentColor;
    border-radius: var(--border-radius);
    border: var(--input-border);
    background-color: var(--c-white);

    &:disabled {
        cursor: not-allowed;
        background-color: var(--c-shade);
    }

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: currentColor;
        opacity: .5;
    }
}

%input--underline {
    padding-inline-start: unset;
    border-radius: unset;
    border-block-start: unset;
    border-inline: unset;
    background-color: transparent;
}
