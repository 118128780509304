@use "src/styles" as *;

.department-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &__wayfinding-wrapper {
        width: 100%;
    }

    &__wayfinding-icon-path {
        fill: rgba(var(--c-white-rgb), .25);
    }

    &__picture-wrapper {
        width: 100%;
        max-height: 15rem;
        overflow: hidden;

        @include for-size(tablet-landscape-up) {
            max-height: 20rem;
        }
    }

    &__picture {
        width: 100%;
        aspect-ratio: 1;
        background-color: var(--c-green-25);
    }

    &__image {
        object-fit: cover;
    }

    &__text-wrapper {
        position: relative;
        z-index: 10;
    }

    &__title {
        @extend %heading-5;

        margin-block-start: var(--spacer-4);
    }

    &__intro {
        margin-block-start: var(--spacer-4);
    }

    &__link-button {
        @extend %button--no-background;

        position: static;
        margin-block-start: var(--spacer-4);
        padding: 0;
        color: currentColor;

        &::after {
            background-color: transparent;
        }
    }

    &__link-label {
        gap: var(--spacer-2);
    }
}
