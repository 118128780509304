.quote {
    font-family: var(--f-elegant);
    font-size: 1rem;
    font-weight: var(--fw-regular);
    font-style: italic;
    line-height: 2;

    &--is-large {
        font-size: 2rem;
        line-height: 1.25;
    }
}
