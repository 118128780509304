.change-password-form {

    &__password-input {
        width: 100%;

        & + & {
            margin-block-start: var(--spacer-3);
        }
    }

    &__password-input-field {
        flex: 1;
    }

    &__submit-button {
        margin-block-start: var(--spacer-3);
    }

    &__error-label {
        margin-block-start: var(--spacer-4);
    }
}
