.login-form {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-3);

    &__heading {
        font-size: 1.75rem;
    }

    &__username-input,
    &__password-input {
        width: 100%;
    }

    &__password-input-field {
        flex: 1;
    }

    &__submit-button {
        padding-block: var(--spacer-2);
    }

    &__error-label {
        margin-block-start: var(--spacer-4);
    }

    &__forgot-password-button {
        width: fit-content;
        margin-block-start: var(--spacer-4);
        font-weight: var(--fw-regular);
        text-decoration: underline;
    }
}
