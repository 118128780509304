@use "src/styles" as *;

.mobile-menu {
    overflow-x: clip;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-block: var(--spacer-5);
        height: 5rem;
    }

    &__back-button {
        @extend %button--no-background;

        padding: var(--spacer-2);
        color: var(--c-black);
    }

    &__logo {
        width: 3.5rem;
        height: 3.5rem;
    }

    &__controls {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: var(--spacer-2);
    }
}
