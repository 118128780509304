@use "src/styles" as *;

$component: "media-grid";

.#{$component} {

    &--layout-1 {

        .#{$component}__wrapper {
            aspect-ratio: 16 / 9;
        }
    }

    &--layout-2 {

        .#{$component}__wrapper {
            display: grid;
            gap: var(--spacer-4);
            grid-template-columns: 1fr 1fr;
            aspect-ratio: 16 / 9;

            @include for-size(tablet-portrait-up) {
                grid-template-columns: 1fr 1.5fr;
            }
        }

        .#{$component}__media-wrapper {
            height: auto;

            &:nth-of-type(2) {
                aspect-ratio: 5 / 3;
            }
        }
    }

    &--layout-3 {

        .#{$component}__wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: var(--spacer-4);

            @include for-size(tablet-portrait-up) {
                grid-template-columns: 5fr 2fr 3fr;
                aspect-ratio: 16 / 9;
            }
        }

        .#{$component}__media-wrapper {

            &:nth-of-type(1) {
                aspect-ratio: 1;
                grid-column: 1 / 3;
            }

            &:nth-of-type(2) {
                height: auto;
                aspect-ratio: 1;
            }

            &:nth-of-type(3) {
                height: auto;
                aspect-ratio: 2 / 3;
            }

            @include for-size(tablet-portrait-up) {

                &:nth-of-type(1) {
                    grid-column: unset;
                }

                &:nth-of-type(2) {
                    aspect-ratio: 2 / 3;
                }
            }
        }
    }

    &__media-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &__picture,
    &__image,
    &__video-theatre {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
