@use "src/styles" as *;

$component: "link-icon-button";

.#{$component} {

    &--has-reveal-animation {

        &:hover,
        &:focus {

            .#{$component}__icon-wrapper {
                width: calc(var(--icon-button-icon-width) + var(--spacer-3));
            }

            .#{$component}__icon {
                opacity: 1;
            }
        }

        &.#{$component}--align-right {

            .#{$component}__icon {
                inset-inline-start: unset;
                inset-inline-end: 0;
                margin-inline: var(--spacer-3) 0;
            }
        }

        .#{$component}__label {
            gap: 0;
        }

        .#{$component}__icon-wrapper {
            position: relative;
            width: 0;
            height: 1.5rem;
            overflow: clip;

            @include prefers-reduced-motion {
                width: calc(var(--icon-button-icon-width) + var(--spacer-3));
            }
        }

        .#{$component}__icon {
            position: absolute;
            inset-inline-start: 0;
            margin-inline: 0 var(--spacer-3);
            transition: opacity .3s ease;
            opacity: 0;
        }
    }

    &--has-push-animation {
        overflow: clip;

        &:hover,
        &:focus {

            .#{$component}__icon-wrapper {
                translate: 0 0;
            }
        }

        .#{$component}__icon-wrapper {
            transition: translate calc(.3s * var(--transition-multiplier)) ease;
            translate: calc(-100% - var(--spacer-5)) 0;
        }
    }

    &--align-right &__label {
        flex-direction: row-reverse;
    }

    &__label {
        display: flex;
        align-items: center;
        gap: var(--spacer-3);
    }

    &__icon-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        transition: width .3s ease;
    }

    &__icon {
        display: block;
        height: 1.5rem;
    }

    &__icon-copy {
        position: absolute;
        inset-inline-start: calc(100% + var(--spacer-5));
    }
}
