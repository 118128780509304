@use "src/styles" as *;

.wishlist-items {

    &__items {
        margin-block-start: var(--spacer-4);
    }

    &__no-items-label {
        @extend %heading-5;

        margin-block-start: var(--spacer-6);
        text-align: center;
        color: var(--c-green-dark);
    }
}
