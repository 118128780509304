@use "src/styles" as *;

.results-count-for-search-term {

    &__text {
        @extend %heading-1;

        display: flex;
        justify-content: center;
        padding-block-start: var(--spacer-5);
        padding-block-end: var(--spacer-2);
        font-size: 1.75rem;
        font-weight: var(--fw-bold);
        color: var(--c-green-dark);
        text-align: center;

        @include for-size(laptop-up) {
            padding-block: var(--spacer-6);
        }
    }
}