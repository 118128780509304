.account-info-block {

    &--has-border {
        padding-block: var(--spacer-4);
        padding-inline: var(--spacer-3);
        border: 1px solid var(--c-green-75);
    }

    &__title {
        width: 100%;
        margin-block-end: var(--spacer-3);
        padding-block-end: var(--spacer-2);
        font-family: var(--f-primary);
        font-weight: var(--fw-medium);
        font-size: .75rem;
        line-height: 1.33;
        text-transform: uppercase;
        border-block-end: 1px solid var(--c-green-75);
    }
}
