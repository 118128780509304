@use "src/styles" as *;

$component: "text-media";

.#{$component} {

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacer-6);

        @include for-size(tablet-portrait-up) {
            flex-direction: row-reverse;
            justify-content: center;
            gap: var(--spacer-7);
        }
    }

    &__media-wrapper {
        display: flex;
        align-items: center;
        min-width: 21.375rem;

        &:has(.#{$component}__picture) {
            overflow: hidden;
        }

        @include for-size(tablet-portrait-up) {
            width: 53.375rem;
            min-height: 37.75rem;
        }
    }

    &__picture,
    &__video {
        width: 100%;
        height: 100%;
    }

    &__image {
        object-fit: cover;
    }

    &__text-wrapper {
        width: 100%;
        height: 100%;

        @include for-size(tablet-portrait-up) {
            width: 27.625rem;
        }
    }

    &__title {
        font-family: var(--f-script);
        font-weight: var(--fw-regular);
        font-size: 3.5rem;
        line-height: 1.14;
        text-transform: uppercase;
        color: var(--c-green-dark);

        @include for-size(tablet-portrait-up) {
            font-size: 4rem;
        }
    }

    &__description-html {
        @extend %html-format;

        margin-block-start: var(--spacer-5);
    }

    &__button {
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-6);
        }
    }
}
