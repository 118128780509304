@use "src/styles" as *;

$component: "brand-carousel";

.#{$component} {

    &__title {
        @extend %heading-3;

        margin-block-end: var(--spacer-5);
        color: var(--c-green-dark);
    }

    &__wrapper {
        position: relative;
        overflow: hidden;
        padding-block: var(--spacer-6);
        white-space: nowrap;

        &:hover .#{$component}__items-wrapper {
            animation-play-state: paused;
        }
    }

    &__footer-wrapper {
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-4);
        }
    }
}
