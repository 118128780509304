@use "src/styles" as *;

.instant-search-global-search {

    &__input {
        --input-border: 1px solid var(--c-black);

        margin-block-end: var(--spacer-1);
        color: var(--c-black);
        width: 100%;

        @include for-size(laptop-up) {
            --input-border: 2px solid var(--c-black);

            font-family: var(--f-display);
            font-size: 1.75rem;
            font-weight: var(--fw-bold);
            margin-block-end: var(--spacer-5);
        }
    }

    &__input-field {
        background-color: transparent;

        &:not(:focus)::placeholder {
            color: var(--c-black);
            opacity: 1;
        }

        @include for-size(laptop-up) {
            font-weight: var(--fw-bold);
        }
    }

    &__input-icon {
        height: 1.5rem;

        @include for-size(laptop-up) {
            height: 2rem;
        }
    }
}
