@use "src/styles" as *;

.content-columns {
    --wrapper-width: 72rem;
    --content-columns-gap-size: 3rem;
    --content-columns-column-amount: 1;
    --content-columns-gap-amount: calc(var(--content-columns-column-amount) - 1);

    @include for-size(tablet-portrait-up) {
        --content-columns-gap-size: 4rem;
    }

    &__title {
        margin-block-end: var(--spacer-5);
    }

    &__column-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            gap: unset;
        }
    }

    &__column {
        width: 100%;

        &:only-child {
            max-width: 42rem;
            margin-inline: auto;
        }

        @include for-size(tablet-portrait-up) {
            width: calc((100% - (var(--content-columns-gap-amount) * var(--content-columns-gap-size))) / var(--content-columns-column-amount));
            box-sizing: content-box;
        }

        &--has-border {
            padding-inline-start: calc(var(--content-columns-gap-size) / 2);
            border-inline-start: 1px solid var(--c-black);

            @include for-size(tablet-portrait-up) {
                padding-inline-start: unset;
                border-inline-start: unset;
            }
        }

        & + & {

            @include for-size(tablet-portrait-up) {
                margin-inline-start: calc(var(--content-columns-gap-size) / 2);
                padding-inline-start: calc(var(--content-columns-gap-size) / 2);
                border-inline-start: 1px solid var(--c-black);
            }
        }
    }
}
