@use "src/styles" as *;

.desktop-main-navigation-drawer {
    position: absolute;
    top: var(--menu-height);
    left: 0;
    z-index: 10;
    width: calc(100dvw - var(--scrollbar-width));
    min-height: calc(50dvh - var(--menu-height));
    max-height: calc(100dvh - var(--menu-height) - 6.25rem);
    overflow-y: auto;
    padding-block: var(--spacer-5);
    border-block-start: 1px solid var(--c-green-25);
    border-end-start-radius: .25rem;
    border-end-end-radius: .25rem;
    background-color: var(--c-background);
    box-shadow: var(--box-shadow);

    &__inner-wrapper {
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(4, 1fr);
    }

    &__column {
        padding-inline-end: var(--spacer-3);

        & + & {
            padding-inline-start: var(--spacer-3);
            border-inline-start: 1px solid var(--c-green-dark);
            transition: opacity .3s ease, translate .3s var(--ease-out-strong);
            opacity: 0;
            translate: negative(var(--spacer-2)) 0;

            &--is-expanded {
                opacity: 1;
                translate: 0 0;
            }
        }

        &:last-child {
            padding-block: var(--spacer-6);
            border: unset;
            opacity: unset;
            translate: unset;
        }
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__image {
        object-fit: cover;
    }
}
