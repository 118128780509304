@use "src/styles" as *;

$component: "button";

.#{$component} {
    @extend %button;

    &--is-small {
        --button-label-offset: calc(-100% - var(--spacer-3));

        padding-block: var(--spacer-2);
        padding-inline: var(--spacer-3);
    }

    &--has-animation {
        overflow: clip;

        &:enabled:hover .#{$component}__label,
        &:enabled:focus .#{$component}__label {
            translate: 0 negative(var(--button-label-offset));
        }

        .#{$component}__label {
            transition: translate calc(.3s * var(--transition-multiplier)) ease;
            translate: 0 0;

            &::after {
                @extend %area-extend;

                content: attr(data-text);
                translate: 0 var(--button-label-offset);
            }
        }
    }

    &__label {
        position: relative;
        z-index: 20;
    }
}
