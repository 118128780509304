@use "src/styles" as *;

.account-order-overview-page {

    &__order-section {
        margin-block-start: var(--spacer-5);
        padding-inline: var(--spacer-5);
    }

    &__order-overview-title {
        @extend %heading-6;

        font-family: var(--f-primary);
    }

    &__order-overview {
        margin-block-start: var(--spacer-4);
    }
}
