@use "src/styles" as *;

.usp-scroller  {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    @include for-size(tablet-landscape-up) {
        display: none;
    }

    &__list {
        display: flex;
        padding-block: var(--spacer-2);
        white-space: nowrap;
        translate: 0 0;
        animation: marquee 25s linear infinite;
    }

    &__item {
        display: inline-flex;
        align-items: center;
        gap: var(--spacer-2);
        padding-block: 0;
        padding-inline: var(--spacer-4);
        border-inline-end: 1px solid rgba(var(--c-green-dark-rgb), .4);
    }
}
