@use "src/styles" as *;

.registration-form-step-email {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
        gap: var(--spacer-5);
    }

    &__picture {
        width: 100%;
    }

    &__title {
        @extend %heading-6;

        font-size: 1.75rem;
        margin-block-end: var(--spacer-5);
    }

    &__description {
        margin-block-start: var(--spacer-5);
    }

    &__column {
        flex: 1;
    }

    &__content {
        flex: 1;
        padding-block: var(--spacer-7);
        padding-inline: var(--spacer-5);
        background-color: var(--c-white);

        &--image {
            padding: var(--spacer-5);

            @include for-size(tablet-landscape-up) {
                order: -1;
            }
        }
    }

    &__email-input {
        width: 100%;
        margin-block-start: var(--spacer-3);
    }

    &__next-step-button {
        width: 100%;
        margin-block-start: var(--spacer-5);
        padding-block: var(--spacer-2);
    }

    &__error-label {
        margin-block-start: var(--spacer-4);
    }

    &__login-button {
        display: block;
        width: fit-content;
        margin-block-start: var(--spacer-4);
        font-weight: var(--fw-regular);
        text-decoration: underline;
    }
}
