@use "src/styles" as *;

.carousel-column {
    overflow: clip;

    &--reveal-prev &__picture--new {
        translate: -100% 0;
    }

    &--reveal-next &__picture--new {
        translate: 100% 0;
    }

    &--reveal-prev#{&}--reveal-new &__picture--new,
    &--reveal-next#{&}--reveal-new &__picture--new {
        transition: translate 1s var(--ease-out-strong);
        translate: 0;
        opacity: 1;

        @include prefers-reduced-motion {
            transition-duration: 1ms;
        }
    }

    &--reveal-new &__picture--current {
        opacity: 1;
    }

    &__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__picture {
        position: relative;
        width: 100%;
        height: 100%;

        &--current {
            z-index: 20;
            opacity: 0;
        }

        &--new,
        &--background {
            position: absolute;
            inset-block: 0;
        }

        &--new {
            z-index: 30;
            will-change: translate;
            translate: 100% 0;
            opacity: 0;
        }

        &--background {
            z-index: 10;
        }
    }

    &__image {
        object-fit: cover;
    }
}
