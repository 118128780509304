@use "src/styles" as *;

.details-step {
    display: flex;
    flex-direction: column;
    padding: var(--spacer-5);
    background-color: var(--c-white);

    &__heading {
        @extend %heading-6;

        margin-block-end: var(--spacer-5);
    }

    &__tabs {
        margin-block: var(--spacer-4);
        position: relative;

        &::after {
            content: "";
            position: absolute;
            z-index: 10;
            inset-block-end: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 2px;
            background-color: var(--c-green-75);
        }
    }

    &__tabs-item-label {
        font-family: var(--f-primary);
        font-weight: var(--fw-medium);
        font-size: 1rem;
        text-transform: initial;
    }
}
