@use "src/styles" as *;

.product-information {

    &__main-wrapper,
    &__brand-wrapper {
        display: flex;
        padding: var(--spacer-5);
        background-color: var(--c-white);

        & + & {
            margin-block-start: var(--spacer-5);
        }
    }

    &__main-wrapper {

        @include for-size(tablet-portrait-up) {
            padding-block: calc(var(--spacer-5) * 4);
        }
    }

    &__main-inner {
        display: flex;
        gap: var(--spacer-5);
        flex-direction: column;
        max-width: min(100%, var(--nested-wrapper-width));
        margin-inline: auto;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: flex-start;
            gap: var(--spacer-8);
        }
    }

    &__title {
        @extend %heading-7;

        margin-block-end: var(--spacer-2);
        font-weight: var(--fw-regular);
    }

    &__description-wrapper {
        flex: 3;
    }

    &__details-wrapper {
        flex: 2;
    }

    &__description,
    &__brand-description {
        @extend %html-format;

        white-space: break-spaces;
    }

    &__table {
        width: 100%;
        margin-block-end: var(--spacer-4);
        border-collapse: collapse;
    }

    &__table-row {
        display: flex;
        gap: var(--spacer-5);
        padding-block-start: var(--spacer-2);
        border-block-end: 1px solid var(--c-green-75);
    }

    &__table-cell {
        width: 10rem;
    }

    &__brand-wrapper {
        gap: var(--spacer-3);
        flex-direction: column;
        align-items: center;

        @include for-size(tablet-portrait-up) {
            gap: var(--spacer-8);
            flex-direction: row;
            padding: var(--spacer-5) var(--spacer-8);
        }
    }

    &__brand-logo {
        flex: 2;
        display: flex;
        justify-content: flex-end;
    }

    &__brand-information {
        flex: 3;
        padding-block: var(--spacer-5);
    }

    &__brand-name {
        margin-block-end: var(--spacer-3);
        font-family: var(--f-primary);
        font-size: .75rem;
        font-weight: var(--fw-medium);
    }

    &__brand-link {
        margin-block-start: var(--spacer-4);
    }
}
