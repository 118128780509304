@use "src/styles" as *;

.space-detail-renderer {

    &__heading {
        @extend %heading-3;
    }

    &__heading,
    &__category-slider {
        margin-block-start: var(--spacer-3);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-5);
        }
    }
}
