@use "src/styles" as *;

.store-detail-header {
    display: grid;
    grid-template-columns: 1fr;
    border-block: 1px solid var(--c-green-dark);

    @include for-size(laptop-up) {
        grid-template-columns: 1fr 1fr;
    }

    &__image {
        width: 100%;
        grid-row: 2;
        object-fit: cover;

        @include for-size(laptop-up) {
            grid-row: unset;
            grid-column-start: 1;
            height: 100%;
            max-height: calc(100vh - 9.375rem);
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 43.75rem;
        padding: var(--spacer-5);
    }

    &__info-title {
        @extend %heading-4;
    }

    &__address {
        position: relative;
        width: fit-content;
        margin-block-start: var(--spacer-2);
    }

    &__route-link {
        display: block;
        width: fit-content;
        margin-block-start: var(--spacer-2);
        color: var(--c-green-dark);

        &::after {
            @extend %area-extend;

            content: "";
        }
    }

    &__info-is-open {
        margin-block-start: var(--spacer-5);
        font-family: var(--f-primary);
    }

    &__info-date {
        font-weight: var(--fw-regular);
    }

    &__info-opening-hours {
        margin-block-start: var(--spacer-5);

        @include for-size(laptop-up) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    &__description {
        margin-block: var(--spacer-3);
        max-width: 35rem;
    }
}
