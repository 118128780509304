.progress {
    position: relative;
    width: 100%;
    height: 3px;
    padding-block: var(--spacer-3);
    overflow: clip;
    color: var(--c-black);
    cursor: pointer;

    &__line {
        position: absolute;
        inset-block-start: 50%;
        z-index: 10;
        width: 100%;
        height: 1px;
        translate: 0 1px;
        background-color: currentColor;
    }

    &__bar {
        position: relative;
        z-index: 20;
        height: 3px;
        background-color: currentColor;
        transition: translate calc(.3s * var(--transition-multiplier)) ease-in-out;
        translate: calc(var(--progress-percentage) - 100%) 0;
    }
}
