@use "src/styles" as *;

.category-header-skeletons {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-5);
    position: relative;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        justify-content: flex-end;
    }

    &__title-wrapper {

        @include for-size(tablet-portrait-up) {
            display: flex;
            flex-direction: column;
            width: 23rem;
        }

        @include for-size(tablet-landscape-up) {
            width: 27.625rem;
        }
    }

    &__title {
        @extend %heading-1;

        font-size: 4rem;
        color: var(--c-green-50);

        @include for-size(tablet-portrait-up) {
            position: absolute;
            inset-block-start: var(--spacer-6);
            z-index: 10;
            width: 35rem;
            margin-block-start: var(--spacer-6);
        }
    }

    &__image {
        @extend %skeleton-fade;

        width: 100%;
        height: 16.25rem;
        overflow: clip;
        background-color: var(--c-green-25);

        @include for-size(tablet-portrait-up) {
            width: 56.75rem;
            min-width: 22rem;
            min-height: 35.75rem;
        }
    }
}
