@use "src/styles" as *;

.dialog {
    --c-backdrop: rgba(var(--c-background-rgb), .8);

    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
    margin: var(--spacer-5);
    padding-block: var(--spacer-6);
    padding-inline: var(--spacer-5);
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--c-white);

    @include for-size(tablet-landscape-up) {
        padding: var(--spacer-5);
    }

    &[open],
    &[open]::backdrop {
        animation: fade-in .3s ease;
    }

    &--is-hidden[open],
    &--is-hidden[open]::backdrop {
        animation: fade-out .3s ease forwards;
    }

    &--has-interactive-backdrop::backdrop {
        cursor: pointer;
    }

    &--has-light-backdrop,
    &--has-dark-backdrop {

        &::backdrop {
            background-color: var(--c-backdrop);
        }
    }

    &--has-dark-backdrop {
        --c-backdrop: rgba(0, 0, 0, 0.32);
    }

    &__close-button {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        z-index: 20;
        padding: var(--spacer-3);
        color: var(--c-accent);
        border-radius: unset;
        border-end-start-radius: var(--border-radius);
        background-color: var(--c-white);
    }
}
