@use "src/styles" as *;

.slider-section {

    &__header-wrapper {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: flex-end;
            gap: var(--spacer-4);
        }
    }

    &__title {
        @extend %heading-3;

        &--is-theme {
            color: var(--c-green-dark);
        }
    }

    &__progress {
        flex: 1;
    }

    &__slider {
        margin-block-start: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-5);
        }
    }

    &__footer-wrapper {
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-4);
        }
    }
}
