@use "src/styles" as *;

.product-media-presentation {

    &__grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: var(--spacer-4);

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__item {
        aspect-ratio: 1;
    }

    &__image {
        width: 100%;
        height: 100%;
        background-color: var(--c-white);
    }
}
