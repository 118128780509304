.product-category-renderer {
    margin-block-start: var(--spacer-5);

    &__header {
        margin-block-start: var(--spacer-5);
    }

    &__block-renderer {
        margin-block-start: var(--spacer-5);
        margin-block-end: var(--spacer-5);
    }
}
