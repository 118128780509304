@use "src/styles" as *;

.blog-post-card-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: var(--spacer-5);
    column-gap: 1px;

    @include for-size(tablet-portrait-up) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include for-size(tablet-landscape-up) {
        grid-template-columns: repeat(3, 1fr);
    }

    &__list-item {

        @include for-size(tablet-portrait-up) {
            padding-inline-end: var(--spacer-5);
        }

        & + & {
            padding-block-start: var(--spacer-5);
            border-block-start: 1px solid var(--c-black);

            @include for-size(tablet-portrait-up) {
                padding-block-start: unset;
                border-block-start: unset;
            }
        }
    }
}
