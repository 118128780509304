@use "src/styles" as *;

.input {
    --input-error-offset: var(--spacer-3);
    --c-search-cancel-icon: var(--c-black);

    flex: 1;
    position: relative;

    &--has-underline {
        --input-error-offset: 0;
    }

    &--has-underline &__icon {
        inset-inline-start: 0;
    }

    &--has-underline &__field {
        @extend %input--underline;

        &--has-icon {
            padding-inline-start: var(--spacer-5);
        }
    }

    &--has-controls {
        --input-error-offset: var(--spacer-2);
    }

    &--has-controls &__field {
        padding-inline-end: var(--spacer-4);
    }

    &__icon {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: var(--spacer-2);
        width: 1.5rem;
        height: auto;
        translate: 0 -50%;
    }

    &__field {
        @extend %input;

        &--has-icon {
            padding-inline-start: var(--spacer-5);
        }

        &--has-error {
            padding-inline-end: calc(var(--input-error-offset) + var(--spacer-5));
        }
    }

    &__error-icon {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-end: var(--input-error-offset);
        height: 1.5rem;
        color: var(--c-error);
        translate: 0 -50%;
    }

    ::-webkit-search-cancel-button {
        appearance: none;
        height: 1rem;
        width: 1rem;
        cursor: pointer;
        background:
            linear-gradient(45deg, transparent 0%, transparent 44%, var(--c-search-cancel-icon) 45%, var(--c-search-cancel-icon) 55%, transparent 56%, transparent 100%),
            linear-gradient(135deg, transparent 0%, transparent 44%, var(--c-search-cancel-icon) 45%, var(--c-search-cancel-icon) 55%, transparent 56%, transparent 100%);

        &:hover {
            --c-search-cancel-icon: var(--c-green-dark);
        }
    }
}
