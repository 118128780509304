@use "src/styles" as *;

.media-grid-examples {

    &__section {

        & + & {
            margin-block-start: var(--spacer-7);

            @include for-size(tablet-portrait-up) {
                margin-block-start: var(--spacer-8);
            }
        }
    }
}
