@use "src/styles" as *;

.my-addresses {

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-end: var(--spacer-5);
        height: var(--spacer-6);
    }

    &__heading {
        @extend %heading-6;
    }

    &__edit-button {
        @extend %button--no-background;

        color: var(--c-black);
        font-weight: var(--fw-regular);
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--spacer-3);

        @include for-size(laptop-up) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__address-list {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--spacer-3);

        @include for-size(laptop-up) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__new-address-form {
        margin-block-start: var(--spacer-3);
    }
}
