@use "src/styles" as *;

.toast {
    --c-toast-state: var(--c-grey);

    display: flex;
    position: fixed;
    width: calc(100% - (var(--wrapper-padding) * 2));
    min-width: 20rem;
    max-width: 36rem;
    padding-block-end: var(--spacer-2);
    color: var(--c-black);
    border-radius: var(--border-radius);
    background-color: var(--c-white);
    transition: translate calc(var(--toast-transition-duration) * var(--transition-multiplier)) ease, opacity var(--toast-transition-duration) ease;
    box-shadow: var(--box-shadow);
    opacity: 0;

    &--inset-block-start {
        inset-block-start: var(--wrapper-padding);
    }

    &--inset-block-end {
        inset-block-end: var(--wrapper-padding);
    }

    &--inset-inline-start {
        inset-inline-start: var(--wrapper-padding);
        translate: negative(calc(100% + var(--wrapper-padding))) 0;
    }

    &--inset-inline-end {
        inset-inline-end: var(--wrapper-padding);
        translate: calc(100% + var(--wrapper-padding)) 0;
    }

    &--positive {
        --c-toast-state: var(--c-success);
    }

    &--warning {
        --c-toast-state: var(--c-warning);
    }

    &--negative {
        --c-toast-state: var(--c-error);
    }

    &--is-revealed {
        translate: 0 0;
        opacity: 1;
    }

    &__message-wrapper {
        width: 100%;
        padding: var(--spacer-3);
        padding-inline: var(--spacer-4) var(--spacer-5);
        border-block-end: 2px solid var(--c-toast-state);
    }

    &__title {
        @extend %heading-6;
    }

    &__description {
        margin-block-start: var(--spacer-3);
    }

    &__close-button {
        @extend %button--no-background;

        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        padding: var(--spacer-3);
        color: inherit;

        &::after {
            background-color: var(--c-shade);
        }
    }

    &__close-icon {
        height: 1rem;
    }
}
