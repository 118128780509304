@use "src/styles" as *;

.order-overview-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: var(--spacer-3);
    border: 1px solid var(--c-green-50);

    &__product-image-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--spacer-3);
    }

    &__product-image-grid:has(&__product-image-item:only-child) {
        grid-template-columns: 1fr;
    }

    &__product-image-item {
        @extend %flex-align-center;
    }

    &__product-image {
        aspect-ratio: 1;
    }

    &__excess-products-indicator {
        @extend %flex-align-center;

        width: 100%;
        height: 100%;
        font-family: var(--f-display);
        font-size: 3.5rem;
        font-weight: var(--fw-bold);
        letter-spacing: 2px;
        color: var(--c-green-dark);
        background-color: var(--c-green-25);
    }

    &__info-wrapper {
        margin-block-start: var(--spacer-4);
        color: var(--c-green-dark);
        border-block-start: 1px solid var(--c-green-50);
    }

    &__order-number,
    &__order-date,
    &__order-total {
        margin-block-start: var(--spacer-3);
    }

    &__link-button {
        @extend %button--is-outline;

        width: 100%;
        margin-block-start: var(--spacer-3);
        padding-block: var(--spacer-2);
        padding-inline: var(--spacer-3);
        color: var(--c-green);
    }
}
