@use "src/styles" as *;

.store-overview-skeletons {

    &__item {
        display: grid;
        grid-template-columns: 1fr;
        align-items: start;
        gap: var(--spacer-3);
        padding: var(--spacer-4);
        border-bottom: 1px solid var(--c-grey-light);

        @include for-size(laptop-up) {
            grid-template-columns: 11.25rem 1fr 1fr auto;
            column-gap: var(--spacer-5);
        }

        &:last-of-type {
            border-block-end: none;
        }
    }

    &__address-line {

        & + & {
            margin-block-start: var(--spacer-2);
        }
    }

    &__link-button {
        width: 6.5rem;
        height: 2.5rem;
        color: var(--c-green-dark);
    }
}
