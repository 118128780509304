@use "src/styles" as *;

.forgot-password-page {

    &__wrapper {
        --wrapper-width: 32rem;
        --wrapper-padding: 0;

        @include for-size(tablet-portrait-up) {
            --wrapper-padding: var(--spacer-5);
        }
    }

    &__forgot-password-form {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: var(--spacer-5);
        padding: var(--spacer-5);
        background-color: var(--c-white);
    }

    &__section {
        margin-block: var(--spacer-5);
    }
}
