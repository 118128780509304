@use "src/styles" as *;

.input-label {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: var(--fw-regular);
    line-height: 1.125;
    color: var(--c-black);

    &__label-wrapper {
        display: flex;
        align-items: center;
    }

    &__tooltip-wrapper {
        @extend %flex-align-center;

        margin-inline-start: var(--spacer-3);
    }

    &__tooltip {
        width: max-content;
        max-width: 32rem;
    }

    &__tooltip-icon {
        height: 1.5rem;
        color: var(--c-green);
    }
}
