@use "src/styles" as *;

.brand-header-skeletons {

    &__title {
        max-width: 30rem;
        height: 4rem;
        margin-block-end: var(--spacer-7);

        @include for-size(tablet-portrait-up) {
            height: 6rem;
        }
    }

    &__content {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        gap: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            grid-template-columns: repeat(2, 1fr);
            gap: 0;
        }
    }

    &__image {
        height: 5rem;
        justify-self: center;

        @include for-size(tablet-portrait-up) {
            height: 7rem;
        }
    }

    &__description {
        padding-inline-start: var(--spacer-5);
        padding-block-start: var(--spacer-5);
        border-block-start: 1px solid var(--c-black);

        @include for-size(tablet-portrait-up) {
            padding-block-start: unset;
            border-inline-start: 1px solid var(--c-black);
            border-block-start: unset;
        }
    }

    &__description-item {
        gap: var(--spacer-5);
    }

    &__description-text {
        margin-block-end: var(--spacer-3);
    };
}