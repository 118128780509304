.order-detail-price-table {
    display: block;
    width: 100%;

    &__body,
    &__footer {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__footer {
        font-weight: var(--fw-bold);
        margin-block-start: var(--spacer-4);
        padding-block: var(--spacer-3);
        border-block-start: 1px solid var(--c-green-75);
    }

    &__row {
        display: flex;
        width: 100%;
    }

    &__cell {

        &:first-child {
            flex: 1;
        }
    }
}
