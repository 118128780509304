@use "src/styles" as *;

.department-overview-skeletons {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: var(--spacer-5);
    column-gap: 1px;

    @include for-size(tablet-portrait-up) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include for-size(tablet-landscape-up) {
        grid-template-columns: repeat(3, 1fr);
    }

    &__list-item {

        @include for-size(tablet-portrait-up) {
            padding-inline-end: var(--spacer-5);
        }
    }

    &__image {
        @extend %skeleton-fade;

        width: 100%;
        height: 20rem;
        background-color: var(--c-green-25);
    }

    &__title {
        margin-block-start: var(--spacer-4);
        font-size: 1.5rem;
    }

    &__intro {
        margin-block-start: var(--spacer-4);
    }

    &__intro-line {

        & + & {
            margin-block-start: var(--spacer-2);
        }
    }

    &__button-wrapper {
        width: 12rem;
    }

    &__button {
        margin-block-start: var(--spacer-4);
    }
}
