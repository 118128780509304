@use "../helpers" as *;

%divider {
    width: 100%;
    height: 1.5rem;
}

%divider-start-start {
    @extend %divider;

    border-block-start: 1px solid currentColor;
    border-inline-start: 1px solid currentColor;
}

%divider-start-end {
    @extend %divider;

    border-block-start: 1px solid currentColor;
    border-inline-end: 1px solid currentColor;
}

%divider-end-start {
    @extend %divider;

    border-block-end: 1px solid currentColor;
    border-inline-start: 1px solid currentColor;
}

%divider-end-end {
    @extend %divider;

    border-block-end: 1px solid currentColor;
    border-inline-end: 1px solid currentColor;
}
