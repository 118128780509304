@use "src/styles" as *;

.styleguide-page {
    --wrapper-width: 72rem;

    padding-block: var(--spacer-3) var(--spacer-6);

    &__flex-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: var(--spacer-3) var(--spacer-8);
        margin-block-start: var(--spacer-5);

        &--column {
            flex-direction: column;
        }
    }

    &__section {
        padding-block-start: var(--spacer-5);

        & + & {
            margin-block-start: var(--spacer-5);
            border-block-start: 1px solid var(--c-black);
        }
    }

    &__section-title {
        @extend %heading-4;
    }

    &__section-examples {
        margin-block-start: var(--spacer-5);
    }
}
