@use "src/styles" as *;

.colour-examples {

    &__section {

        & + & {
            margin-block-start: var(--spacer-5);
        }
    }

    &__section-title {
        @extend %heading-5;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, 6rem);
        gap: var(--spacer-4);
        width: 100%;
        max-width: 44rem;
        margin-block-start: var(--spacer-4);
    }

    &__color {
        @include circle(4rem);

        box-shadow: 0 0 .5rem rgba(0, 0, 0, .1);

        &--black { background-color: var(--c-black); }
        &--white { background-color: var(--c-white); }
        &--green-dark { background-color: var(--c-green-dark); }
        &--green { background-color: var(--c-green); }
        &--green-75 { background-color: var(--c-green-75); }
        &--green-50 { background-color: var(--c-green-50); }
        &--green-25 { background-color: var(--c-green-25); }
        &--grey-light { background-color: var(--c-grey-light); }

        &--background { background-color: var(--c-background); }

        &--sand-soft { background-color: var(--c-sand-soft); }
        &--sand-warm { background-color: var(--c-sand-warm); }
        &--ocher-soft { background-color: var(--c-ocher-soft); }
        &--caramel { background-color: var(--c-caramel); }
        &--chocolate { background-color: var(--c-chocolate); }
        &--khaki { background-color: var(--c-khaki); }
        &--cement { background-color: var(--c-cement); }
        &--turquoise { background-color: var(--c-turquoise); }
        &--charcoal { background-color: var(--c-charcoal); }
        &--green-light { background-color: var(--c-green-light); }
        &--green-garage { background-color: var(--c-green-garage); }
        &--rust { background-color: var(--c-rust); }

        &--outdoor-1 { background-color: var(--c-outdoor-1); }
        &--outdoor-2 { background-color: var(--c-outdoor-2); }
        &--favorites-brown { background-color: var(--c-favorites-brown); }
        &--favorites-blue { background-color: var(--c-favorites-blue); }
        &--favorites-pink { background-color: var(--c-favorites-pink); }
        &--goods-1 { background-color: var(--c-goods-1); }
        &--goods-2 { background-color: var(--c-goods-2); }
        &--deals { background-color: var(--c-deals); }
        &--sale { background-color: var(--c-sale); }

        &--success { background-color: var(--c-success); }
        &--warning { background-color: var(--c-warning); }
        &--error { background-color: var(--c-error); }
    }
}
