@use "src/styles" as *;

.register-call-to-action {

    &__heading {
        font-size: 1.75rem;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--spacer-5);
        padding: var(--spacer-6) var(--spacer-4);
        text-align: center;
        color: var(--c-white);
        background-color: var(--c-green-dark);
    }

    &__image {
        width: 100%;
    }

    &__button {
        @extend %button--is-outline;

        --c-button-hover: var(--c-green);
    }
}
