@import "src/styles";

.faq {

    &__title {
        @extend %heading-5;

        margin-block-end: var(--spacer-5);

        &--is-sticky {
            margin: 0;
            padding: var(--spacer-4);
            background-color: var(--c-green-50);
            color: var(--c-black);

            @include for-size(tablet-portrait-up) {
                padding: var(--spacer-5);
                padding-block-end: 0;
            }
        }
    }

    &__card {
        padding: var(--spacer-4);
        border-radius: 0 0 var(--spacer-1) var(--spacer-1);
        background-color: var(--c-green-50);
        box-shadow: var(--box-shadow);

        @include for-size(tablet-portrait-up) {
            padding: var(--spacer-5);
        }
    }

    &__link-wrapper {
        margin-block: var(--spacer-5);
        color: var(--c-green-dark);
    }

    &__link-title {
        font-family: var(--f-primary);
        font-size: 1.125rem;
        font-weight: var(--fw-medium);
        line-height: 1.5;
        color: currentColor;
    }

    &__link-text {
        margin-block-start: var(--spacer-5);
    }

    &__link {
        font-weight: var(--fw-bold);
    }
}
