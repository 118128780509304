@use "src/styles" as *;

.desktop-main-navigation {

    &__toggle-button {
        color: var(--c-black);
    }

    &__drawer {
        pointer-events: none;
        transition: opacity .3s ease, translate .3s var(--ease-out-strong);
        opacity: 0;
        translate: 0 negative(var(--spacer-2));

        &--is-open {
            pointer-events: all;
            opacity: 1;
            translate: 0 0;
        }
    }
}
