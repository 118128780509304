@use "src/styles" as *;

.carousel-navigation-button {
    position: relative;

    &__icon-path {
        fill: rgba(var(--c-white-rgb), .25);
    }

    &__touch-icon {
        position: absolute;
        inset-block-end: 0;
        height: 6rem;
        stroke-width: 1px;
        color: var(--c-black);

        &--is-prev {
            inset-inline-start: 0;
        }

        &--is-next {
            inset-inline-end: 0;
        }
    }

    &__button {
        @extend %area-extend;
        @extend %button--no-background;

        &:disabled {
            cursor: none;
            background-color: transparent;
        }

        &::after {
            content: unset;
        }
    }

    &__button-label {
        @extend %area-extend;

        display: none;

        @include touch-screen {
            display: block;
        }
    }
}
