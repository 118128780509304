@use "src/styles" as *;

.spaces-overview-renderer {

    &__list-wrapper {

        @include for-size(tablet-portrait-up) {
            padding: unset;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: var(--spacer-6);
        column-gap: var(--spacer-3);
        margin-block-start: var(--spacer-6);

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(3, 1fr);
            row-gap: var(--spacer-5);
        }
    }

    &__list-item {

        @include for-size(tablet-portrait-up) {
            padding-inline-end: var(--spacer-3);
        }
    }
}
