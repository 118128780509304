@use "src/styles" as *;

.tooltip-wrapper {
    width: fit-content;

    &__tooltip {
        --tooltip-x: 0px;
        --tooltip-y: 0px;
        --tooltip-offset-x: -50%;
        --tooltip-offset-y: calc(-100% - var(--spacer-2));

        position: absolute;
        top: var(--tooltip-y);
        left: var(--tooltip-x);
        padding-block: var(--spacer-2);
        padding-inline: var(--spacer-3);
        font-size: .875rem;
        line-height: 1.5;
        color: var(--c-black);
        pointer-events: none;
        border-radius: var(--border-radius);
        background-color: var(--c-green-25);
        transition: scale calc(.2s * var(--transition-multiplier)) ease, opacity .1s ease;
        will-change: left, top;
        transform-origin: bottom;
        translate: var(--tooltip-offset-x) var(--tooltip-offset-y);
        scale: .5;
        opacity: 0;

        @include prefers-reduced-motion {
            scale: 1;
        }

        &::after {
            @include square(.5rem);

            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            border-inline-start: var(--line);
            border-block-end: var(--line);
            background-color: inherit;
            translate: -50% 50%;
            rotate: -45deg;
        }

        @include touch-screen {
            top: calc(var(--tooltip-y) - var(--spacer-4));
            left: var(--wrapper-padding);
            width: calc(100vw - (var(--wrapper-padding) * 2));
            translate: 0 var(--tooltip-offset-y);

            &::after {
                content: unset;
            }
        }

        &--is-fixed {
            position: fixed;
        }

        &--is-revealed {
            scale: 1;
            opacity: 1;
        }
    }
}
