@use "src/styles" as *;

.store-overview {
    margin-inline: auto;
    border-radius: .375rem;
    border: 1px solid var(--c-grey-light);
    background-color: var(--c-white);

    @include for-size(tablet-portrait-up) {
        max-width: 75vw;
    }

    &__item {
        display: grid;
        grid-template-columns: 1fr;
        align-items: baseline;
        gap: var(--spacer-3);
        padding: var(--spacer-4);
        border-bottom: 1px solid var(--c-grey-light);

        @include for-size(laptop-up) {
            grid-template-columns: 11.25rem 1fr 1fr auto;
            column-gap: var(--spacer-5);
        }

        &:last-of-type {
            border-block-end: none;
        }
    }

    &__title {
        font-family: var(--f-primary);
    }

    &__opening-hours {
        display: flex;
        flex-direction: row;

        &--is-open {
            font-weight: var(--fw-medium);
            color: var(--c-grey-dark);
        }
    }

    &__link-button {

        @include for-size(laptop-up) {
            margin-inline-start: auto;
        }
    }
}
