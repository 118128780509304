@use "src/styles" as *;

.blog-categories-skeletons {
    display: flex;
    gap: var(--spacer-5);
    max-width: var(--wrapper-width);
    margin-inline: auto;
    padding-inline: var(--wrapper-padding);
    overflow: auto;

    @include for-size(tablet-landscape-up) {
        justify-content: center;
    }

    &__list-item {
        width: 10rem;
        min-width: 10rem;
    }

    &__category {
        width: 100%;
        font-size: 2rem;
    }
}
