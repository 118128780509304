@use "src/styles" as *;

.order-detail-product-variant {

    &__picture-wrapper {
        @extend %flex-align-center;

        padding: var(--spacer-2);
    }

    &__picture {
        width: 100%;
        max-width: 12rem;
    }

    &__info-wrapper {
        width: 100%;
        margin-block-start: var(--spacer-3);
    }

    &__brand {
        width: fit-content;
        font-family: var(--f-primary);
        font-weight: var(--fw-medium);
        font-size: .75rem;
        letter-spacing: normal;
        text-transform: uppercase;
    }

    &__product-link {
        margin-block: var(--spacer-2);
    }

    &__name {
        width: fit-content;
        font-family: var(--f-primary);
        font-weight: var(--fw-regular);
        font-size: 1rem;
        margin-block-start: var(--spacer-2);
    }

    &__price {
        margin-block-start: var(--spacer-2);
    }
}
