@use "src/styles" as *;

.desktop-main-navigation-toggle {
    @extend %button--no-background;

    padding: var(--spacer-2);
    translate: negative(var(--spacer-2)) 0;

    &__label-wrapper {
        display: flex;
        text-transform: uppercase;
    }

    &__hamburger-icon {
        width: 1.5rem;
    }

    &__label {
        display: block;
        margin-inline-start: var(--spacer-3);
    }

    &__chevron-icon {
        width: 1.5rem;
        margin-inline-start: var(--spacer-2);
        transition: scale .1s ease;

        &--is-open {
            scale: 1 -1;
        }
    }
}
