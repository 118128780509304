@use "src/styles" as *;

.instant-search-page-hit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding-inline: var(--spacer-2);
    padding-block: var(--spacer-4) var(--spacer-2);
    border-bottom: 1px solid var(--c-green-75);
    color: var(--c-green-dark);

    &:hover,
    &:focus {
        background-color: var(--c-green-25);
    }

    &__type {
        width: fit-content;
        margin-block: var(--spacer-2);
        padding: var(--spacer-1) var(--spacer-2);
        font-size: .875rem;
        font-weight: var(--fw-regular);
        color: var(--c-white);
        background-color: var(--c-green-dark);
    }

    &__title {
        padding-block: var(--spacer-2);
        font-weight: var(--fw-bold);

        &::after {
            @extend %area-extend;

            content: "";
        }
    }

    &__icon {
        min-width: 1.5rem;
        width: 1.5rem;
        height: auto;
        color: var(--c-black);
    }
}
