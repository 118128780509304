@use "src/styles" as *;

.brand-detail-page {
    padding-block-start: var(--spacer-4);

    @include for-size(tablet-portrait-up) {
        padding-block-start: var(--spacer-6);
    }

    &__block {
        margin-block-start: var(--spacer-7);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-8);
        }
    }
}
