@use "src/styles" as *;

.product-list-call-to-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-block: var(--spacer-7);
    padding-inline: var(--spacer-5);
    background-color: var(--c-green-25);

    &--is-image-type {
        padding: 0;
    }

    &--has-background-image {
        background-image: var(--product-list-cta-image);
        background-size: cover;
        background-position: center;
    }

    &__image-link {
        @extend %area-extend;

        opacity: 0;
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__image {
        object-fit: cover;
    }

    &__title {
        @extend %heading-7;
    }

    &__description {
        margin-block-start: var(--spacer-5);
        font-family: var(--f-elegant);
        font-style: italic;
        font-size: 1.5rem;
        line-height: 1.67;
    }

    &__link-button {
        margin-block-start: var(--spacer-6);
    }
}
