@use "src/styles" as *;

.split-wrapper {
    width: 100%;
    margin-inline: auto;
    padding-inline: var(--wrapper-padding);

    &--is-start,
    &--is-end {

        @include for-size(tablet-portrait-up) {
            max-width: calc(var(--wrapper-width) / 2);
            margin: 0;
            padding: 0;
        }
    }

    &--is-start {

        @include for-size(tablet-portrait-up) {
            margin-inline: auto 0;
            padding-inline: var(--wrapper-padding) 0;
        }
    }

    &--is-end {

        @include for-size(tablet-portrait-up) {
            margin-inline: 0 auto;
            padding-inline: 0 var(--wrapper-padding);
        }
    }
}
