@import "src/styles";

.accordion {
    width: 100%;
    padding-inline-start: 1rem;

    &--has-border {
        border-block-end: 1px solid var(--c-green);
    }

    &[open] > &__summary &__icon {
        scale: 1 -1;
    }

    &__summary {
        display: flex;
        align-items: center;
        padding-block: var(--spacer-3) var(--spacer-2);
        padding-inline: 0 var(--spacer-3);
        line-height: 2;
        cursor: pointer;
        user-select: none;
        font-weight: var(--fw-medium);
        color: var(--c-green-dark);

        &::marker,
        &::-webkit-details-marker {
            display: none;
        }
    }

    &__icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-inline-start: auto;
        color: var(--c-green-dark);
        transition: scale .1s ease;
    }

    &__content-wrapper {
        padding: var(--spacer-3);
        padding-block-start: var(--spacer-2);
        padding-inline-start: 0;
    }

    &__content {
        @extend %html-format;
    }
}
