@use "src/styles" as *;

$component: "media-banner";

.#{$component} {
    --c-media-banner-title: var(--c-white);
    --c-media-banner-subtitle: var(--c-white);
    --c-media-banner-button-background: var(--c-green-dark);

    position: relative;
    height: 43.75rem;

    @include for-size(big-desktop-up) {
        height: 63rem;
    }

    &__media-wrapper,
    &__picture,
    &__video {
        width: 100%;
        height: 100%;
    }

    &__image,
    &__video-element {
        object-fit: cover;
        max-height: unset;
    }

    &__cta-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        padding-block-end: var(--spacer-5);

        &:has(.#{$component}__title) {
            align-items: center;
            padding-block-end: 0;
        }
    }

    &__cta-contents-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: var(--spacer-3);
    }

    &__title {
        font-size: 3.5rem;
        color: var(--c-media-banner-title);
        text-align: center;
        width: 20rem;

        @include for-size(tablet-portrait-up) {
            font-size: 4.5rem;
        }
    }

    &__subtitle {
        @extend %heading-6;

        color: var(--c-media-banner-subtitle);
        text-align: center;
        margin-block-end: var(--spacer-5);
    }

    &__link-button {
        position: static;
        height: fit-content;
        background-color: var(--c-media-banner-button-background);

        &::before {
            @extend %area-extend;

            content: "";
        }

        &::after {
            content: unset;
        }
    }
}
