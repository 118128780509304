@use "src/styles" as *;

.brand-navigation-skeletons {
    margin-block-start: var(--spacer-5);

    &__navigation {
        margin-block-end: var(--spacer-3);
    };

    &__columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-block: var(--spacer-6);

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &__item {
        margin-block: var(--spacer-3);
    }
}