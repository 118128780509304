@use "src/styles" as *;

.product-list {

    &__heading {
        @extend %heading-4;

        margin-block-end: var(--spacer-5);
        color: var(--c-green-dark);
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--spacer-4);
        margin-block-start: var(--spacer-4);

        @include for-size(tiny-screen-only) {
            grid-template-columns: 1fr;
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(4, 1fr);
            row-gap: var(--spacer-5);
        }
    }

    &__product-card,
    &__call-to-action {

        @include for-size(tablet-portrait-up) {
            min-height: 30rem;
        }
    }

    &__call-to-action {
        grid-column: span 2;

        @include for-size(tiny-screen-only) {
            grid-column: span 1;
        }

        @include for-size(tablet-portrait-up) {
            max-height: 30rem;
        }
    }

    &__no-results-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: var(--spacer-5);
    }

    &__no-results {
        @extend %heading-5;

        color: var(--c-green-dark);
        text-align: center;
    }

    &__slider {
        margin-block-start: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-5);
        }
    }

    &__pagination {
        margin-block-start: var(--spacer-7);
    }
}
