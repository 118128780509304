@use "src/styles" as *;

.content-blocks-page {
    --c-accent: var(--c-black);

    padding-block-end: var(--spacer-8);

    &__block {

        & + & {
            margin-block-start: var(--spacer-5);

            @include for-size(tablet-portrait-up) {
                margin-block-start: var(--spacer-7);
            }
        }
    }
}
