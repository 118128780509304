@use "src/styles" as *;

$component: "video-theatre";

.#{$component} {
    width: 100%;
    height: 100%;

    &__inline-wrapper {
        height: 100%;
    }

    &__inline-video {
        height: 100%;
    }

    &__inline-video-element {
        object-fit: cover;
    }

    &__poster-wrapper {
        @extend %flex-align-center;

        position: relative;
        width: 100%;
        height: 100%;
        background-color: var(--c-white);
    }

    &__poster,
    &__poster-placeholder {
        width: 100%;
        height: 100%;
    }

    &__poster-image {
        object-fit: cover;
    }

    &__poster-placeholder {
        width: 100%;
        background-color: var(--c-green-25);
    }

    &__play-button-wrapper {
        @extend %area-extend;
        @extend %flex-align-center;
    }

    &__dialog-button-icon {
        height: 1rem;
    }

    &__dialog {
        width: var(--video-theatre-width);
        height: var(--video-theatre-height);
        margin: auto;
        padding: 0;
    }

    &__dialog-close-button {
        @include circle(3rem);

        inset-block-start: var(--spacer-3);
        inset-inline-end: var(--spacer-3);
        padding: 0;
        transition: opacity .3s ease;
        opacity: 0;

        &:hover,
        &:focus {
            opacity: 1;
        }

        &--is-faded {
            opacity: .5;
        }
    }

    &__dialog-content {
        @extend %flex-align-center;

        &:hover ~ .#{$component}__dialog-close-button,
        &:focus-within:focus-visible ~ .#{$component}__dialog-close-button {
            opacity: .5;
        }
    }

    &__video-element {
        height: unset;
        max-height: calc(100dvh - 4rem);
    }
}
