@use "src/styles" as *;

.desktop-sub-navigation {

    &__group-list,
    &__item-list {
        display: flex;
    }

    &__group-list {
        flex-wrap: wrap;
        translate: negative(var(--spacer-3)) 0;
    }

    &__link {
        display: block;
        padding: var(--spacer-3);
        font-weight: var(--fw-medium);
        line-height: 1.5;

        &--is-active {
            color: var(--c-green-dark);
        }
    }
}
