@use "src/styles" as *;

.footer-skeletons {
    background-color: var(--c-background);

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        column-gap: var(--spacer-4);
        row-gap: var(--spacer-5);
        padding-block: var(--spacer-5);
        padding-inline: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            flex-wrap: nowrap;
            justify-content: center;
            padding-block: var(--spacer-6);
        }
    }

    &__nav {
        width: 12rem;

        @include for-size(tablet-portrait-up) {
            width: 16rem;
        }
    }

    &__nav-title {
        font-size: 1.25rem;
    }

    &__nav-list {
        margin-block-start: var(--spacer-4);
    }

    &__nav-item {

        & + & {
            margin-block-start: var(--spacer-2);
        }
    }
}
