@use "src/styles" as *;

$component: "wayfinding-button";

.#{$component} {
    @extend %button--no-background;

    padding: 0;
    color: var(--c-black);

    &:enabled:hover,
    &:enabled:focus {

        .#{$component}__fill {

            &::after {
                scale: 1;
            }
        }
    }

    &::after {
        content: unset;
    }

    &__label {
        display: flex;
        align-items: center;
        gap: var(--spacer-2);
        font-size: 1.125rem;
    }

    &__icon-wrapper {
        width: 3.75rem;
        height: 3.75rem;
        position: relative;
    }

    &__icon {
        height: 3.75rem;
        stroke-width: 2px;
    }

    &__fill {
        @extend %area-extend;

        clip-path: polygon(80.4% 18.7%, 80.4% 71.3%, 67.9% 71.3%, 67.9% 40.4%, 27.8% 80.6%, 18.9% 71.7%, 59.3% 31.3%, 27.9% 31.3%, 27.9% 18.7%, 80.4% 18.7%);

        &::after {
            @extend %area-extend;

            content: "";
            background-color: currentColor;
            transform-origin: bottom left;
            transition: scale .2s ease;
            scale: 0;
        }
    }
}
