@use "src/styles" as *;

.textarea {
    display: block;
    width: fit-content;

    &__field-wrapper {
        position: relative;
    }

    &__field {
        @extend %input;

        min-width: 24rem;
        min-height: 6em;
        resize: vertical;

        &--has-error {
            padding-inline-end: var(--spacer-5);
        }
    }

    &__error-icon {
        position: absolute;
        inset-block-start: calc(var(--spacer-2) + .25em);
        inset-inline-end: var(--spacer-3);
        color: var(--c-error);
    }
}
