@use "src/styles" as *;

.order-detail-renderer {

    &__title {
        @extend %heading-6;

        line-height: 1.33;
    }

    &__wrapper {
        margin-block-start: var(--spacer-4);

        @include for-size(tablet-landscape-up) {
            display: grid;
            grid-template-columns: 1fr 2fr;
        }
    }

    &__product-variant-list {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-3);
    }

    &__detail-wrapper {

        @include for-size(tablet-landscape-up) {
            padding-inline-start: var(--spacer-4);
            border-inline-start: 1px solid var(--c-green-75);
        }
    }

    &__info-block {

        & + & {
            margin-block-start: var(--spacer-4);
        }
    }

    &__labelled-value {

        & + & {
            margin-block-start: var(--spacer-3);
        }
    }

    &__price-table {
        margin-block-start: var(--spacer-3);
    }

    &__address {
        margin-block-start: var(--spacer-3);
    }
}
