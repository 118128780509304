@use "src/styles" as *;

.guest-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacer-5);
    width: 100%;

    &__heading {
        @extend %heading-6;

        font-size: 1.75rem;
        margin-block-end: var(--spacer-5);
    }

    &__column {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__content {
        flex: 1;
        background-color: var(--c-white);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: var(--spacer-3);

        &--image {
            @include for-size(tablet-landscape-up) {
                order: -1;
            }
        }
    }

    &__section-label {
        margin-block-end: var(--spacer-3);
        padding-block-end: var(--spacer-2);
        font-weight: var(--fw-bold);
        border-bottom: 1px solid var(--c-green-50);
    }

    &__input-row {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-3);

        @include for-size(laptop-up) {
            flex-direction: row;
        }
    }

    &__text-input {
        width: 100%;
    }

    &__password-input-field {
        flex: 1;
    }

    &__next-step-button,
    &__submit-button {
        margin-block-start: var(--spacer-3);
        padding-block: var(--spacer-2);
    }

    &__error-label {
        margin-block-start: var(--spacer-4);
    }

    &__login-button {
        display: block;
        width: fit-content;
        margin-block-start: var(--spacer-4);
        font-weight: var(--fw-regular);
        text-decoration: underline;
    }
}
