.filter-button {
    gap: var(--spacer-2);
    padding: var(--spacer-2);
    text-transform: uppercase;

    &:enabled:hover &__icon,
    &:enabled:focus &__icon {
        rotate: .25turn;
    }

    &__icon {
        height: 1.25rem;
        transition: rotate calc(.3s * var(--transition-multiplier)) ease;
    }
}
