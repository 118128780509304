.select {
    width: 100%;
    cursor: pointer;
    font-weight: var(--fw-regular);
    background-color: transparent;

    &:focus-within &__control {
        border-color: var(--c-accent) !important;
        box-shadow: none;
    }

    &--is-disabled &__control {
        background-color: var(--c-shade) !important;
    }

    &__control {
        border-radius: var(--border-radius) !important;
        border: var(--input-border) !important;
        background-color: var(--c-white) !important;

        &--is-focused {
            box-shadow: none;
        }
    }

    &__input-container {
        margin: 0 !important;
        padding: 0 !important;
        line-height: 1.5;
    }

    &__input {
        padding-block: var(--spacer-2);
        padding-inline: 0;
        color: var(--c-body) !important;
    }

    &__placeholder {
        color: currentColor !important;
        opacity: .5;
    }

    &__indicators {
        padding-inline-end: var(--spacer-3);

        .icon {
            width: 1.5rem;
            height: auto;
        }
    }

    &__indicator-separator {
        display: none;
    }

    &__indicator {
        color: currentColor !important;
    }

    &__value-container {
        padding: var(--spacer-2) var(--spacer-3) !important;
    }

    &__single-value {
        color: currentColor !important;
    }

    &__menu {
        border: var(--input-border);
        background-color: var(--c-white) !important;
        z-index: 100 !important;
    }

    &__menu-list {
        max-height: 14rem !important;
    }

    &__option {
        color: currentColor !important;

        &--is-focused {
            color: var(--c-white) !important;
            background-color: var(--c-green-75) !important;
        }

        &--is-selected {
            color: var(--c-white) !important;
            background-color: var(--c-accent) !important;
        }
    }
}
