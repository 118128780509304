.service-form {

    &__input-wrapper {
        display: flex;
    }

    &__input {
        width: 100%;
    }

    &__field {
        background-color: transparent;
    }

    &__submit-button {
        height: auto;
        padding-block: unset;
        padding-inline: var(--spacer-2);
    }

    &__recaptcha-disclaimer {
        margin-block-start: var(--spacer-4);
    }

    &__error-label {
        margin-block-start: var(--spacer-4);
    }
}
