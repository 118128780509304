@use "src/styles" as *;

.pagination-link {
    @extend %button--no-background;

    padding-block: var(--spacer-3);
    padding-inline: var(--spacer-2);
    font-weight: var(--fw-regular);
    color: currentColor;

    &--is-active {
        font-weight: var(--fw-bold);
    }
}
