@use "src/styles" as *;

.cache-buster {
    display: flex;
    justify-content: center;
    position: fixed;
    inset-block-end: 0;
    inset-inline-start: 0;
    z-index: 10;
    width: 100%;
    padding: var(--spacer-4);
    translate: 0 100%;
    transition: translate .3s ease;

    &--is-revealed {
        translate: 0 0;
    }

    &__card {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding-block: var(--spacer-3);
        padding-inline: var(--spacer-4);
        border-radius: var(--border-radius-large);
        background-color: var(--c-white);
        backdrop-filter: var(--input-backdrop-filter);
        box-shadow: var(--box-shadow);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__description {
        font-weight: var(--fw-medium);
    }

    &__button {
        margin-block-start: var(--spacer-3);
        padding-block: var(--spacer-2);
        padding-inline: var(--spacer-3);

        @include for-size(tablet-portrait-up) {
            margin-block-start: 0;
            margin-inline-start: var(--spacer-3);
        }
    }
}
