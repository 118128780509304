@use "src/styles" as *;

.space-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &__wayfinding-wrapper {
        width: 100%;
    }

    &__wayfinding-icon-path {
        fill: rgba(var(--c-white-rgb), .25);
    }

    &__image-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 10rem;
        overflow: hidden;
        background-color: var(--c-green-25);

        @include for-size(tablet-landscape-up) {
            height: 20rem;
        }
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__image {
        object-fit: cover;
    }

    &__text-wrapper {
        position: relative;
        z-index: 10;
    }

    &__details-wrapper {
        margin-block-start: var(--spacer-3);
        color: var(--c-charcoal);
    }

    &__category,
    &__published-date {
        font-family: var(--f-primary);
        font-weight: var(--fw-medium);
        font-size: .75rem;
        text-transform: uppercase;
        font-style: normal;
        line-height: .875rem;
        letter-spacing: .72px;
    }

    &__published-date {
        margin-inline-start: var(--spacer-3);
        padding-inline-start: var(--spacer-3);
        border-inline-start: 1px solid var(--c-green-75);
    }

    &__title {
        @extend %heading-5;

        margin-block-start: var(--spacer-4);
    }

    &__intro {
        margin-block-start: var(--spacer-3);
    }

    &__link-button {
        @extend %button--no-background;

        position: static;
        margin-block-start: var(--spacer-4);
        padding: 0;
        color: currentColor;

        &::after {
            background-color: transparent;
        }
    }

    &__link-label {
        gap: var(--spacer-2);
    }
}
