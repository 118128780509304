@use "src/styles" as *;

.blog-categories {

    &__tabs {
        justify-content: unset;
        gap: var(--spacer-5);
        max-width: var(--wrapper-width);
        margin-inline: auto;
        padding-inline: var(--wrapper-padding);
        overflow: auto;
        font-size: 2rem;

        @include for-size(tablet-landscape-up) {
            justify-content: center;
        }

        &--has-scroll-bar {

            @include for-size(tablet-landscape-up) {
                justify-content: unset;
            }
        }
    }
}
