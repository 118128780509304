@use "src/styles" as *;

.dialog-with-button {

    &__button {
        @extend %button--no-background;

        margin-block-end: var(--spacer-4);
        color: var(--c-black);
    }

    &__dialog {
        width: 50rem;
        height: fit-content;
        margin: auto;

        @include for-size(tablet-landscape-up) {
            padding: var(--spacer-6);
        }
    }
}
